import React, { useEffect, useState } from 'react'
import { Col, Row, Modal, Button, Alert } from "react-bootstrap";
import backIcon from "../../assets/back.svg";
import { useHistory, useParams } from "react-router-dom";
import BranchService from "../BranchService";
import OtherService from "../../components/OtherService"
import BranchSelector from "../BranchSelector";
import { connect, useDispatch } from "react-redux";
import * as rdvActions from "../../actions";
import ServiceClosed from "../ServiceClosed";
import { useTranslation } from "react-i18next";
import NoAgent from "../NoAgent";
import i18next from "i18next";
import CovidMessage from "../CovidMessage";
import ArrowStepper from '../ArrowStepper/ArrowStepper';
import { GiHazardSign } from 'react-icons/gi'
import { FaServicestack } from "react-icons/fa"
import closeErrorAlert from "../../assets/07 Buttons-06 With Icons-Icon Button-01 Default-01 On White.svg"
import errorNext from "../../assets/Error_severe.svg"
import './ServiceSelection.css'
import { useMediaQuery } from 'react-responsive'
import getCookieFromConstants from "../../constants"
import * as ckie from "../../cookieFunctions"
import AssistanceRedirection from '../../components/AssistanceRedirection/AssistanceRedirection';


const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: e => dispatch(rdvActions.getServices(e),),
        
        fromAppointmentToCurrentQueue: (a, o) => dispatch(rdvActions.fromAppointmentToCurrentQueue(a, o)),
    }

}
const ServiceSelection = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const { apiKey } = useParams();
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const goBack = () => {
        // dispatch({ type: 'SET_OPERATION', payload: null })
        // dispatch({ type: 'SET_BRANCH', payload: null })
        // dispatch({ type: 'SET_SERVICE', payload: null })
        // history.push('/' + sessionStorage.getItem('rdv_apikey') + "/operation")
    }

    // console.log(props.rdvState)
    const handleCancel = () => {
        setShow(true)
    }

    useEffect(() => {

       
        if (props.rdvState.branch !== null) {
            dispatch({ type: 'IS_LOADING', payload: true });
            props.getServices(props.rdvState.branch)
        } else goBack()

        window.scrollTo(0, 0)

    }, [])

    let o;
    let a = props.rdvState.apiKey;
     let j = props.rdvState.operation;
    //  console.log("operation ", props.rdvState.operation)
    if (j === "appointment") {
      o = props.rdvState.branch?.office;
    } else {
      o = props.rdvState.branch?.id;
    }

    useEffect(() => {

        // console.log('La valeur du cookie est : ', getCookie('currentQueueCookie'))
        // console.log("le cookie des Consts : ", getCookieFromConstants('rdv_apikey'))
        ckie.setSpecificCookie("Test", "Test Value", 100)
        // console.log("Valeur de ckie", ckie.getSpecificCookie("Test")) 
        

        if (props.rdvState.operation == null) {
            dispatch({ type: 'IS_LOADING', payload: true });
            history.push("/noroute")
        } 
        window.scrollTo(0, 0)
        
    
        
    }, [])

    
    var dtExpire = new Date();
    dtExpire.setTime(dtExpire.getTime() + 240 * 1000);

    function setCookie(cName, cValue, cExpire){
        document.cookie = cName + ' = ' + escape(cValue) + '  ' +
                  ((cExpire == undefined) ? '' : ('; expires = ' + cExpire.toGMTString()));
      }
   
   
      function  getCookie(cName){
        if(document.cookie.length == 0)
          return null;
   
        var regSepCookie = new RegExp('(; )', 'g');
        var cookies = document.cookie.split(regSepCookie);
   
        for(var i = 0; i < cookies.length; i++){
          var regInfo = new RegExp('=', 'g');
          var infos = cookies[i].split(regInfo);
          if(infos[0] == cName){
            return unescape(infos[1]);
          }
        }
        return null;
      }

    const handleClose = () => setShow(false);
      let operationInCookie = getCookie('currentQueueCookie')
    const handleYes = () => {

        if (props.rdvState.source === 'appointment') {
            // window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + sessionStorage.getItem('operationKey')  + '/' + ckie.getSpecificCookie('rdv_lang')
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + ckie.getSpecificCookie('operationKey')  + '/' + ckie.getSpecificCookie('rdv_lang')
        } else {
            // if(sessionStorage.getItem('operationKey')) {
            //     window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + sessionStorage.getItem('operationKey')  + '/' + localStorage.getItem('rdv_lang')
            //     } else {
            //         window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.id  + '/' + localStorage.getItem('rdv_lang')
            //     }
            if(props.rdvState.operation === "currentqueue"){
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.id  + '/' + ckie.getSpecificCookie('rdv_lang')
      
          }else{
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.office  + '/' + ckie.getSpecificCookie('rdv_lang')
          }
               

        }
        // if(props.rdvState.operation === "currentqueue"){
        //     window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.id  + '/' + localStorage.getItem('rdv_lang')
      
        //   }else{
        //     window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.office  + '/' + localStorage.getItem('rdv_lang')
        //   } 
    }
    const goNext = () => {
        if (props.rdvState.service) {
            dispatch({ type: 'NEXT_STEP' });
            if (props.rdvState.operation === "appointment") {
               
                history.push('/' + props.rdvState.company?.apikey + '/availability')
            } else history.push('/' + props.rdvState.company?.apikey + '/informations')

        } else {
            setShowAlert(true)
            window.location.href = "#alertShowService";
        }
    }

    const compare = (a, b) => {
        if (a.translate) {
            if (a.translate[i18next.language] < b.translate[i18next.language]) {
                return -1;
            }
            if (a.translate[i18next.language] > b.translate[i18next.language]) {
                return 1;
            }
        }
        if (a.name) {
            if (a.name[i18next.language] < b.name[i18next.language]) {
                return -1;
            }
            if (a.name[i18next.language] > b.name[i18next.language]) {
                return 1;
            }
        }
        //return 0;
    }
    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px and max-width: 1023px)'
    })

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })
    return <div id={'ServiceSelection'}>

        {/* Start of Modal */}
        <Modal style={{borderRadius: `0`}} show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>{(props.rdvState.operation === 'currentqueue') ? (<div style={{ textSize: `24px`, opacity: `1` }}>{t('about_leave_queue')}</div>) : (
                    <div style={{font: `normal normal bold 24px/26px Helvetica Neue`, textSize: `24px`, opacity: `1` }}>{t('about_leave_appointment')}</div>)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{(props.rdvState.operation === 'currentqueue') ? (<div style={{font: `normal normal bold 16px/19px Helvetica Neue`}}>{t('leave_queue_question')}</div>) : (<div style={{font: `normal normal bold 16px/19px Helvetica Neue`}}>{t('cancel_appointment_question')}</div>)}
            </Modal.Body>
            <Modal.Footer className={'d-flex justify-content-start'}>
                <button  style={{background: `#FFFFFF`, border: `2px solid #000000`, opacity: `1`, textColor: `#000000`, width: `62px`, height: `40px` }} onClick={handleClose}>
                    {t('no')}
                </button>
                <button style={{ border: `none`, background: `#FF7900`, textColor: `#000000`, opacity: `1`, width: `62px`, height: `40px` }} onClick={handleYes}>
                    {t('yes')}
                </button>
            </Modal.Footer>
        </Modal>
        {/* End of Modal */}

        <div id={'alertShowService'}>
            
            {isMobile ? 
                <div className="col-12">
                {(props.rdvState.operation === 'currentqueue') ? (<h1 style={{ fontWeight: `bold`, marginLeft: `5px`, fontFamily: `Helvetica Neue` }}>{t('join_the_current_queue')}</h1>) : (
                    < h1 style={{ fontWeight: `bold`, marginLeft: `5px`, fontFamily: `Helvetica Neue` }}>{t('book_appointment')}</h1>)}

            </div>
            :
            <div className="col-12">
                {(props.rdvState.operation === 'currentqueue') ? (<h1 style={{ fontWeight: `bold`, marginLeft: `60px`, fontFamily: `Helvetica Neue` }}>{t('join_the_current_queue')}</h1>) : (
                    <  h1 style={{ fontWeight: `bold`, marginLeft: `60px`, font: `normal normal bold 50px Helvetica Neue` }}>{t('book_appointment')}</h1>)}

            </div>

    
        }
            
            <hr className="col-sm-12 col-lg-12 col-md-12" style={{marginBottom: `50px`, marginTop: `15px`}}></hr>

            <div hidden={props.rdvState.serviceClosed !== null} className="col" style={{ width: `810px`, marginRight: `auto`, marginLeft: `auto` }}>

            <ArrowStepper />

            {(props.rdvState.operation === 'currentqueue') ? (<div style={{ font: `normal normal bold 16px/19px Helvetica Neue`, marginTop: `40px`, marginBottom: `40px`, color: `#000000D9`, opacity: `1`, letterSpacing: `-0.13px`, height: `24px` }}>{t('visit_reason_text')}</div>) : (
                <div style={{ font: `normal normal bold 16px/19px Helvetica Neue`, marginTop: `40px`, marginBottom: `40px`, color: `#000000D9`, opacity: `1`, letterSpacing: `-0.13px`, color: `#000000D9`, height: `24px` }}>{t('appointment_reason_text')}</div>)}

            {showAlert == true ? (<>
                <div className="d-flex flex-nowrap row justify-content-between" style={{width: `100%`, height: `58px`, border: `2px solid #CD3C14`, opacity: `1`, marginTop: `30px`, marginBottom: `30px`, marginLeft: `auto`, marginRight: `auto` }}>
                    <div className="d-flex flex-nowrap">
                        <img src={errorNext} style={{ color: `#CD3C14`, marginLeft: `24px`, marginRight: `15px`, width: `30px`, height: `27px`, marginTop: `auto`, marginBottom: `auto` }} />
                        <div style={{ font: `normal normal bold 15px/19px Helvetica Neue`, letterSpacing: `-0.1px`, color: `#000000`, opacity: `1`, marginTop: `auto`, marginBottom: `auto` }}>
                            {t('warningService')}
                        </div>
                    </div>

                    <img src={closeErrorAlert} onClick={() => setShowAlert(false)} style={{ marginTop: `auto`, marginBottom: `auto`, marginRight: `23px` }} />
                </div>
            </>) : null}


</div>

                {/*Start Service List */}
                {isMobile ? 
                <div className="col d-flex  mx-auto justify-content-center flex-wrap" style={{width: `810px`}}>
                    {props.rdvState.services.sort(compare).map((s, i) => (
                        <div key={i}  ><BranchService service={s} /></div>))}
                    {(props.rdvState.operation === "appointment" && props.rdvState.serviceClosed == null && props.rdvState.services.length > 0 && props.rdvState.company.showOtherService != 0) ? (<div ><OtherService /></div>) : null}
                    {(apiKey == "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" || apiKey == "aGxyVlNLY0xOLzN0MGxzcHpheEFBdz09") && props.rdvState.serviceClosed == null && props.rdvState.services.length > 0 ? <div><AssistanceRedirection /> </div> : null}
                   
                   
                    {props.rdvState.serviceClosed !== null ? (<ServiceClosed />) : ('')}
                    </div>
                    :
                        <div className="col d-flex  mx-auto justify-content-between flex-wrap" style={{width: `810px`}}>
                            {props.rdvState.services.sort(compare).map((s, i) => (
                                <div key={i}  ><BranchService service={s} /></div>))}
                            {(props.rdvState.operation === "appointment" && props.rdvState.serviceClosed == null && props.rdvState.services.length > 0 && props.rdvState.company.showOtherService != 0) ? (<div ><OtherService /></div>) : null}
                           {(apiKey == "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" || apiKey == "aGxyVlNLY0xOLzN0MGxzcHpheEFBdz09") && props.rdvState.serviceClosed == null && props.rdvState.services.length > 0 ? <div><AssistanceRedirection /> </div> : null}
                           
                            {props.rdvState.serviceClosed !== null ? (<ServiceClosed />) : ('')}
                            </div>
                }   

                {/* </Row> */}

                {/* End Service List */}



                




                <div hidden={props.rdvState.serviceClosed !== null} className="col" style={{ width: `810px`, marginRight: `auto`, marginLeft: `auto` }}>

                {/*Start of 3 Buttons */}
            
                {isMobile ? 
                <div style={{paddingRight: `20px`, paddingLeft: `20px`, marginTop: `30px`}}>
                <Row className={'d-flex justify-content-between flex-nowrap'} style={{marginBottom: `10px`}}>
                    <div style={{font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FFFFFF`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }} onClick={handleCancel}>
                    </div>
                    <div style={{font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FFFFFF`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }} onClick={handleCancel}>
                    </div>

                </Row>
                <Row><button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FF7900`, border: `none`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, width: `100%`, height: `40px` }}
                            onClick={goNext}>{t('next')}</button></Row>
                </div>
                :
                <Row className='d-flex justify-content-between' style={{marginBottom: `20px`}}>
                    <div style={{font: `normal normal bold 16px/19px Helvetica Neue`, marginLeft: `15px`, backgroundColor: `#FFFFFF`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }} onClick={handleCancel}>
                        
                    </div>
                    <div>
                    <div style={{font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FFFFFF`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }} onClick={handleCancel}>
                    </div>
                        <button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FF7900`, border: `none`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px`, marginRight: `15px` }}
                            onClick={goNext}>{t('next')}</button>
                    </div>
                </Row>

                }
                {/* End of 3 buttons */}

            </div>

        </div>
    </div>;
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelection);
