import React from 'react';
import './NoAgent.css'
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import BranchSelector from "../BranchSelector";
import {Col, Row} from "react-bootstrap";
import * as rdvActions from "../../actions";
import noAgent from '../../assets/noagent.png'
import {useTranslation} from "react-i18next";
import * as ckie from "../../cookieFunctions"

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: e => dispatch(rdvActions.getServices(e),)
    }
}
const NoAgent = (props) => {
    let history = useHistory();
    const {t} = useTranslation();
    const goBack = () => {
        // history.push('/' + sessionStorage.getItem('rdv_apikey') + "/service")
        history.push('/' + ckie.getSpecificCookie('rdv_apikey') + "/service")
    }
    return <div className={'NoAgent'} id={'NoAgent'}>
        <Row>
            <Col></Col>
            <Col sm={6}>
                <div className={'text-center m-2'}><img src={noAgent} alt={''} width={180}/></div>
                <div className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-header text-center m-2 sorry' : 'text-center m-2 sorry'}>{t('we_sorry')}</div>
                <div hidden={props.rdvState.operation === 'currentqueue'}
                     className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-header text-center m-2 noattendant' : 'text-center m-2 noattendant'}>{t('no_attendant_avail')}</div>
                <div hidden={props.rdvState.operation === 'appointment'}
                     className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-header text-center m-2 noattendant' : 'text-center m-2 noattendant'}>{t('no_service_avail')}</div>
                <div hidden={props.rdvState.operation === 'currentqueue'}
                     className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-header text-center m-2 select' : 'text-center m-2 select'}>{t('please_try_of')} <Link
                    to={'/' + props.rdvState.company?.apikey + '/service'}>{t('click_here')} </Link>{t('to_select_serv')}
                </div>
                <div hidden={props.rdvState.operation === 'appointment'}
                     className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-header text-center m-2 noattendant' : 'text-center m-2 noattendant'}>{t('please_try_branch')}</div>

            </Col>
            <Col></Col>
        </Row>
        <div style={{marginBottom: '15%'}}>
            <Row>
                <Col></Col>
                <Col sm={6}>
                    <BranchSelector goBack={goBack} fetchServices={props.getServices}/>
                </Col>
                <Col></Col>
            </Row>
        </div>
    </div>
};

export default connect(mapStateToProps, mapDispatchToProps)(NoAgent);
