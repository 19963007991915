import React from 'react';
import './TimeSlot.css'
import {connect, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import clockSvg from "../../../src/assets/clock.svg";
import { Col, Row } from "react-bootstrap";
import { AiFillClockCircle } from 'react-icons/ai';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const TimeSlot = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();

  const handleSelect = () => {
    dispatch({type: 'SET_SLOT', payload: props.slot})
    dispatch({type: 'SET_DATE', payload: props.day.date})
      // history.push('/' + props.rdvState.company?.apikey + '/informations')
  }
  return <div 
  // style={{backgroundColor: props.rdvState.slot !== null && props.rdvState.slot == props.slot && props.day.date == props.rdvState.date ? '#FF7900' : `#FFFFFF`}} 
  style={props.rdvState.slot !== null && props.rdvState.slot == props.slot && props.day.date == props.rdvState.date ? { backgroundColor: `#FF7900`, border: `1px solid #FF7900` } : {
    backgroundColor: `#FFFFFF`
  }}

      className={'TimeSlot'} id={'TimeSlot'} onClick={handleSelect}>
  
    <Row>
    <AiFillClockCircle style={{height: `20px`, width: `20px`, marginRight: `5px`, marginLeft: `15px`}}/>
    <div className={'orange-body slotMargTop'} style={{paddingTop: `0px`,font: `normal normal bold 16px/19px Helvetica Neue` ,color: '#000000', opacity: `1`, height: `20px`}}>{props.slot}</div>
    </Row>

    {/* <div className="d-flex flex-nowrap row justify-content-between" style={{ height: `40px`, border: `2px solid #CD3C14`, opacity: `1`, marginTop: `30px`, marginBottom: `30px`, marginLeft: `auto`, marginRight: `auto`, width: `780px` }}>
                    <div className="d-flex flex-nowrap">
                        <AiFillClockCircle style={{ color: `#CD3C14`, marginLeft: `15px`, marginRight: `5px`, width: `20px`, height: `20px`, marginTop: `auto`, marginBottom: `auto` }} />
                        <div className={'orange-body'} style={{height: `19px`, letterSpacing: `-0.1px`, color: `#000000`, opacity: `1`, marginTop: `auto`, marginBottom: `auto` }}>
                        {props.slot}
                        </div>
                    </div>

                  </div> */}

  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlot);
