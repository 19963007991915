import React, { useEffect } from 'react';
import './DaySlot.css'
import moment from 'moment';
import {connect, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}



const DaySlot = (props) => {
  const {t, i18n} = useTranslation();
  let dispatch = useDispatch();

// useEffect(() => {
// //   dispatch({type: 'SET_SLOT', payload: props.slot})
// //   dispatch({type: 'SET_DATE', payload: props.day.date})
//  console.log(props.day.hours)
// }, [props.rdvState.slot])

  return <div className={'DaySlot'} id={'DaySlot'}>
    <div  className={ props.rdvState.slot !== null  && props.day.date == props.rdvState.date ? 'circle DaySelected' : 'circle DayNotSelected'}
          
    >
      {/* <div className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body day' : 'day'}  style={{color: props.rdvState.company?.color_scheme.secondary_color}}>{t(moment(props.day.date).locale(i18n.language).format('dddd'))}</div>
      <div className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body date' : 'date'} style={{color: props.rdvState.company?.color_scheme.secondary_color}}>{moment(props.day.date).locale(i18n.language).format("MMMM Do, YYYY")}</div> */}
      
      <div className={props.day.hours.length  ? 'orange-body date' : 'orange-body date emptyDate'} style={{marginBottom: `7px`}}>{moment(props.day.date).locale(i18n.language).format("Do")}</div>
      <div className={props.day.hours.length  ? 'orange-body day' : 'orange-body day emptyDate'}  style={{textTransform: `capitalize`}}>{t(moment(props.day.date).locale(i18n.language).format('dddd'))}</div>
      </div>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(DaySlot);

