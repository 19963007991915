import React, { useEffect, useState } from 'react';
import backIcon from "../../assets/back.svg";
import { Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row, Modal, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import serviceSvg from '../../assets/service.svg'
import branchSvg from '../../assets/branch.svg'
import locationSvg from '../../assets/slocation.svg'
import dateSvg from '../../assets/date.svg'
import timeSvg from '../../assets/time.svg'
import countries from "../../datas/countries";
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import moment from "moment";
import * as rdvActions from "../../actions";
import NameInitials from "../NameInitials";
import { useTranslation } from "react-i18next";
import ArrowStepper from '../ArrowStepper/ArrowStepper';
import { useMediaQuery } from 'react-responsive'
import * as ckie from "../../cookieFunctions"


const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getTicket: (e) => dispatch(rdvActions.getTicket(e)),
        getRdv: (e) => dispatch(rdvActions.getRdv(e))
    }
}

const SummaryScreen = (props) => {

    // console.log("new props", props);
    const { t, i18n } = useTranslation();
    const { apiKey } = useParams();
    let history = useHistory();
    const [show, setShow] = useState(false);

  const [note, setNote] = useState();

    i18next.on('languageChanged', function (lng) {
        moment.locale(lng);
    });


    let dispatch = useDispatch();
    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;

    const handleCancel = () => {
        // dispatch({type: 'SET_VISITOR', payload: null})
        // dispatch({type: 'SET_SLOT', payload: null})
        // dispatch({type: 'SET_DATE', payload: null})
        // dispatch({type: 'SET_SERVICE', payload: null})
        // dispatch({type: 'SET_BRANCH', payload: null})
        // dispatch({type: 'SET_OPERATION', payload: null})

        // history.push('/' + sessionStorage.getItem('rdv_apikey') + "/operation");
        setShow(true)
    }

    const handleClose = () => setShow(false);

    function getCookie(cName) {
        if (document.cookie.length == 0)
            return null;

        var regSepCookie = new RegExp('(; )', 'g');
        var cookies = document.cookie.split(regSepCookie);

        for (var i = 0; i < cookies.length; i++) {
            var regInfo = new RegExp('=', 'g');
            var infos = cookies[i].split(regInfo);
            if (infos[0] == cName) {
                return unescape(infos[1]);
            }
        }
        return null;
    }


    let operationInCookie = getCookie('currentQueueCookie')
    const handleYes = () => {
        dispatch({ type: 'SET_SERVICE', payload: null })
        dispatch({ type: 'SET_SLOT', payload: null })
        setShow(false)

        if (props.rdvState.source === 'appointment') {
            // window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + sessionStorage.getItem('operationKey')  + '/' + ckie.getSpecificCookie('rdv_lang')
            window.location.href = '/' + props.rdvState.company?.apikey + '/' + "appointment" + '/' + ckie.getSpecificCookie('operationKey') + '/' + ckie.getSpecificCookie('rdv_lang')
        } else {
            if (props.rdvState.operation === "currentqueue") {
                window.location.href = '/' + props.rdvState.company?.apikey + '/' + props.rdvState.operation + '/' + props.rdvState.branch?.id + '/' + ckie.getSpecificCookie('rdv_lang')

            } else {
                window.location.href = '/' + props.rdvState.company?.apikey + '/' + props.rdvState.operation + '/' + props.rdvState.branch?.office + '/' + ckie.getSpecificCookie('rdv_lang')
            }


        }
        // if(props.rdvState.operation === "currentqueue"){
        //     window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.id  + '/' + localStorage.getItem('rdv_lang')

        //   }else{
        //     window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.office  + '/' + localStorage.getItem('rdv_lang')
        //   }
        // if(sessionStorage.getItem('operationKey')) {
        //     window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + sessionStorage.getItem('operationKey')  + '/' + localStorage.getItem('rdv_lang')
        //     } else {
        //         window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.id  + '/' + localStorage.getItem('rdv_lang')
        //     }
    }


    const goNext = () => {
        // history.push('/' + sessionStorage.getItem('rdv_apikey') + "/summary");
        history.push('/' + ckie.getSpecificCookie('rdv_apikey') + "/summary");
    }
    const goBack = () => {
        dispatch({ type: 'SET_VISITOR', payload: null })
        dispatch({ type: 'PREVIOUS_STEP' });
        history.push('/' + ckie.getSpecificCookie('rdv_apikey') + "/informations")
    }

    const handleConfirm = () => {
        if (props.rdvState.confirmBtnActive === true) {

            if (props.rdvState.operation === 'currentqueue') {
                let data = {
                    "ApiKey": props.rdvState.company?.apikey,
                    "StoreId": props.rdvState.branch.id,
                    "ServiceId": props.rdvState.service.id,
                    "Fullname": props.rdvState.visitor.name,
                    /*"Mobile": country.callingCodes + '' + phone,*/
                    "Mobile": props.rdvState.visitor.phone,
                    "Email": props.rdvState.visitor.email,
                    "language": i18next.language,
                    "branch": props.rdvState.branch.id,
                    "service": props.rdvState.service.id,
                    "CustomerType": props.rdvState.customerTypeChosen ? props.rdvState.customerTypeChosen  : 0
                }

                props.getTicket(data)
            }
            var agent = "0";
            if (props.rdvState.company?.AllowSelectAgents != 0) {
                agent = props.rdvState.agent?.agent;
            }
            if (props.rdvState.operation === 'appointment') {

                let data = {
                    "fullname": props.rdvState.visitor.name,
                    "mobile": props.rdvState.visitor.phone,
                    "code_country": props.rdvState.visitor.country.callingCodes[0],
                    "email": props.rdvState.visitor.email,
                    "bookdate": props.rdvState.date + ' ' + props.rdvState.slot + ':00',
                    "comment": "",
                    "language": i18next.language,
                    "branch": props.rdvState.branch.office,
                    "service": props.rdvState.service.service,
                    "agent": agent,
                    "customertype": props.rdvState.customerTypeChosen ? props.rdvState.customerTypeChosen : 0
                }
                if (note != undefined && note != "" && note != null){
                    data["note"] = note
                }else{ data["note"] = ""}
                props.getRdv(data);

            } else {
                let data = {}
            }
        }
        dispatch({ type: 'ACTIVE_SUMMARY_BTN', payload: false })
    }



    let opLabel;
    if (props.rdvState.operation === 'appointment') {
        opLabel = t('confirm_appointment')
    } else if (props.rdvState.operation === 'currentqueue') {
        opLabel = t('confirm_join_queue')

    } else {
        opLabel = ""
    }

    useEffect(() => {
        if (props.rdvState.ticket !== null) {
            // history.push('/' + props.rdvState.company?.apikey + '/success')
            history.push('/' + props.rdvState.company?.apikey + '/sent')
        } else if (props.rdvState.rdv !== null) {
            history.push('/' + props.rdvState.company?.apikey + '/sent')
        }
    }, [props.rdvState.ticket, props.rdvState.rdv])


    useEffect(() => {
        if (props.rdvState.operation == null) {
            dispatch({ type: 'IS_LOADING', payload: true });
            history.push("/noroute")
        }
        window.scrollTo(0, 0)
        // console.log((props.rdvState.visitor?.email).match(/.{1,7}/g))

        // console.log(props.rdvState)
    }, [])


    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px and max-width: 1023px)'
    })

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })
    let emailMobile1 = "";
    let emailMobile2 = "";
    let emailMobile = "";
    if (props.rdvState?.visitor?.email != ""){
        emailMobile1 = (props.rdvState.visitor?.email).match(/.{1,10}/g)[0] + " ";
        emailMobile2 = (props.rdvState.visitor?.email).match(/.{1,10}/g)[1] + " ";
        emailMobile = emailMobile1 + emailMobile2
    }else{
        emailMobile1 = "";
        emailMobile2 = "";
    }

    return <div>

        {/* Start of Modal */}
        <Modal style={{ borderRadius: `0` }} show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>{(props.rdvState.operation === 'currentqueue') ? (<div style={{ textSize: `24px`, opacity: `1` }}>{t('about_leave_queue')}</div>) : (
                    <div style={{ font: `normal normal bold 24px/26px Helvetica Neue`, textSize: `24px`, opacity: `1` }}>{t('about_leave_appointment')}</div>)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{(props.rdvState.operation === 'currentqueue') ? (<div style={{ font: `normal normal bold 16px/19px Helvetica Neue` }}>{t('leave_queue_question')}</div>) : (<div style={{ font: `normal normal bold 16px/19px Helvetica Neue` }}>{t('cancel_appointment_question')}</div>)}
            </Modal.Body>
            <Modal.Footer className={'d-flex justify-content-start'}>
                <button style={{ background: `#FFFFFF`, border: `2px solid #000000`, opacity: `1`, textColor: `#000000`, width: `62px`, height: `40px` }} onClick={handleClose}>
                    {t('no')}
                </button>
                <button style={{ border: `none`, background: `#FF7900`, textColor: `#000000`, opacity: `1`, width: `62px`, height: `40px` }} onClick={handleYes}>
                    {t('yes')}
                </button>
            </Modal.Footer>
        </Modal>
        {/* End of Modal */}


        <Row>


            {isMobile ?
                <div className="col-12">
                    {(props.rdvState.operation === 'currentqueue') ? (<h1 style={{ fontWeight: `bold`, marginLeft: `5px`, fontFamily: `Helvetica Neue` }}>{t('join_the_current_queue')}</h1>) : (
                        < h1 style={{ fontWeight: `bold`, marginLeft: `5px`, fontFamily: `Helvetica Neue` }}>{t('book_appointment')}</h1>)}

                </div>
                :
                <div className="col-12">
                    {(props.rdvState.operation === 'currentqueue') ? (<h1 style={{ fontWeight: `bold`, height: `60px`, marginLeft: `60px`, fontFamily: `Helvetica Neue` }}>{t('join_the_current_queue')}</h1>) : (
                        <  h1 style={{ fontWeight: `bold`, marginLeft: `60px`, fontFamily: `Helvetica Neue` }}>{t('book_appointment')}</h1>)}

                </div>


            }
        </Row>
        <hr className="col-sm-12 col-lg-12 col-md-12" style={{ marginBottom: `50px`, marginTop: `15px` }}></hr>


        <div className="col" style={{ width: `820px`, marginRight: `auto`, marginLeft: `auto` }}>

            <ArrowStepper />
        </div>



        <Row>
            <Col sm={2} lg={3} md={2} xs={1} ></Col>
            <Col sm={8} lg={6} md={8} xs={10} className={'serviceDetails'}>

                <div style={{ maxWidth: `596px`, marginRight: `auto`, marginLeft: `auto` }}>
                    <Row style={{ marginTop: `30px`, margingBottom: `40px`, color: `#000000D9`, opacity: `1`, height: `24px`, fontWeight: `bold` }}>{t('summary')}</Row>

                    <Row className="mt-4">
                        <Col md={6} lg={6} sm={6} xs={6} className={'colPad'} >
                            {/* <Row style={{color: "#000000", opacity: "1", fontWeight: "bold", letterSpacing: "-0.2px"}}>Ticket Number</Row>
                            <Row style={{marginBottom: "7px"}}>{props.rdvState.ticket?.ticket}</Row> */}

                            <Row style={{ color: "#000000", opacity: "1", font: `normal normal bold 16px/22px Helvetica Neue`, letterSpacing: "-0.2px" }}>{t('name')}</Row>
                            <Row style={{ marginBottom: "7px", font: `normal normal normal 16px/19px Helvetica Neue` }}>{props.rdvState.visitor?.name}</Row>
                            <Row style={{ color: "#000000", opacity: "1", font: `normal normal bold 16px/22px Helvetica Neue`, letterSpacing: "-0.2px" }}>{t('phone')}</Row>
                            <Row style={{ marginBottom: "7px", font: `normal normal normal 16px/19px Helvetica Neue` }}>{props.rdvState.visitor?.phone}</Row>
                            <Row style={{ color: "#000000", opacity: "1", font: `normal normal bold 16px/22px Helvetica Neue`, letterSpacing: "-0.2px" }}>{t('mail')}</Row>
                            {isMobile ?
                                <Row style={{ font: `normal normal normal 16px/19px Helvetica Neue`, width: `50px`, overflowWrap: `break-word` }}><p>{emailMobile1}{emailMobile2 ? emailMobile2 : null}</p></Row>
                                :
                                <Row style={{ font: `normal normal normal 16px/19px Helvetica Neue`, width: `50px`, overflowWrap: `break-word` }}><p>{props.rdvState.visitor?.email}</p></Row>
                            }


                        </Col>
                        <Col md={6} lg={6} sm={6} xs={6} className={'colPad'} >
                            <Row style={{ color: "#000000", opacity: "1", font: `normal normal bold 16px/22px Helvetica Neue`, letterSpacing: "-0.2px" }}>{t('branch')}</Row>
                            <Row style={{ font: `normal normal normal 16px/19px Helvetica Neue` }}>{props.rdvState.branch?.Name}
                                {props.rdvState.branch?.name}
                            </Row>
                            <Row style={{ marginBottom: "7px", font: `normal normal normal 16px/19px Helvetica Neue` }}>
                                {props.rdvState.branch?.address}
                                {props.rdvState.branch?.Address}</Row>
                            <Row style={{ textTransform: `capitalize`, color: "#000000", opacity: "1", font: `normal normal bold 16px/22px Helvetica Neue`, letterSpacing: "-0.2px" }}>{props.rdvState.operation === "appointment" ? t('appointment_reason') : t('visit_reason')}</Row>
                            <Row style={{ marginBottom: "7px" }}>{props.rdvState.service?.translate ? props.rdvState.service?.translate[i18next.language] : props.rdvState.service?.name ? props.rdvState.service?.name[i18next.language] : ''}</Row>

                            {props.rdvState.operation === "appointment" ? (<>
                                <Row style={{ color: "#000000", opacity: "1", font: `normal normal bold 16px/22px Helvetica Neue`, letterSpacing: "-0.2px" }}>{t('date_time')}</Row>
                                <Row style={{ font: `normal normal normal 16px/19px Helvetica Neue` }}>{props.rdvState.date ? moment(props.rdvState.date, 'YYYY-MM-DD').locale(i18n.language).format("dddd Do, MMMM YYYY") : moment().format("dddd Do, MMMM YYYY")} {" "} {t('at')} {" "} {props.rdvState.slot}</Row>

                            </>) : null}
                        </Col>
                    </Row>




                    {/* Start Mobile buttons */}
                    {isMobile ?
                        <div style={{ paddingRight: `20px`, paddingLeft: `20px`, marginTop: `40px` }}>
                            <Row className={'d-flex justify-content-between flex-nowrap'} style={{ marginBottom: `10px` }}>
                                <button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FFFFFF`, borderColor: `#000000`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }} onClick={handleCancel}>
                                    {t('cancel')}
                                </button>
                                <button style={{
                                    font: `normal normal bold 16px/19px Helvetica Neue`,
                                    backgroundColor: `#000000`, textTransform: `capitalize`, color: `#FFFFFF`, opacity: `1`, height: `40px`, paddingRight: `20px`, paddingLeft: `20px`, border: `2px solid #000`,
                                    borderRadius: `0px`
                                }} onClick={() => {
                                    goBack()
                                }}>{t('back')}</button>

                            </Row>
                            <Row><button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FF7900`, border: `none`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, width: `100%`, height: `40px` }}
                                onClick={handleConfirm}>{opLabel}</button></Row>
                        </div>
                        :
                        <Row className='d-flex justify-content-between' style={{ marginBottom: `20px`, width: `596px`, marginTop: `40px` }}>
                            <button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FFFFFF`, borderColor: `#000000`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }} onClick={handleCancel}>
                                {t('cancel')}
                            </button>
                            <div>
                                <button style={{
                                    font: `normal normal bold 16px/19px Helvetica Neue`,
                                    backgroundColor: `#000000`, textTransform: `capitalize`, color: `#FFFFFF`, opacity: `1`, height: `40px`, paddingRight: `20px`, paddingLeft: `20px`, border: `2px solid #000`, marginRight: `12px`,
                                    borderRadius: `0px`
                                }} onClick={() => {
                                    goBack()
                                }}>{t('back')}</button>
                                <button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FF7900`, border: `none`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px`, marginRight: `10px` }}
                                    onClick={handleConfirm}>{opLabel}</button>
                            </div>
                        </Row>

                    }
                    {/* End Mobile buttons */}


                </div>




            </Col>
            <Col sm={2} lg={3} md={2} xs={1}></Col>
        </Row>



        {/*Start of 3 Buttons */}


        {/* End of 3 Buttons */}



        {/* <div className={'row d-flex justify-content-between'} style={{width: `600px`, marginRight:`auto`, marginLeft: `auto`, marginTop: `50px`}}>
                
                 <div>   
                    <button  style={{background: `#FFFFFF`, border: `2px solid #000000`, opacity: `1`, paddingLeft: `20px`, paddingRight: `20px`, height: `41px`}} onClick={handleCancel}>{t('cancel')}</button>
                    </div>
                    <div>
                    <button  style={{color: `#FFFFFF`, border: `0`, marginRight: `20px`,paddingLeft: `20px`, paddingRight: `20px`, height: `40px`, background: `#000000 0% 0% no-repeat padding-box`, opacity: `1`}} onClick={goBack}>{t('back')}</button>
                    
                         <button style={{marginRight: `0px`, color: `#000000`, border: `0`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px`, background: `#FF7900 0% 0% no-repeat padding-box`, opacity: `1`}} 
                                 onClick={handleConfirm}>{opLabel}
                         </button>
                         </div>
                    
                
            </div> */}
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryScreen);
