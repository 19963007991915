import React, {useEffect} from 'react';
import './AgentsScreen.css'
import {Col, Row} from "react-bootstrap";
import backIcon from "../../assets/back.svg";
import {useHistory, useParams} from "react-router-dom";
import BranchAgent from "../BranchAgent";
import {connect, useDispatch} from "react-redux";
import * as rdvActions from "../../actions";
import NoAgent from "../NoAgent";
import {useTranslation} from "react-i18next";
import  * as ckie from "../../cookieFunctions"

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAgents: (s, b) => dispatch(rdvActions.getAgents(s, b)),
  }
}
const AgentsScreen = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  const {t} = useTranslation();
  const {apiKey} = useParams();
  const goBack = () => {
    // history.push('/' + sessionStorage.getItem('rdv_apikey') + "/service")
    history.push('/' + ckie.getSpecificCookie('rdv_apikey') + "/service")
  }
  useEffect(() => {
    if (props.rdvState.service) {
      dispatch({type: 'IS_LOADING', payload: true});
      props.getAgents(props.rdvState.service, props.rdvState.branch)
    } else goBack()

    window.scrollTo(0, 0)
  }, [])

  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  const BackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} style={{marginRight: 10}} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
  <g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
      <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" stroke={props.rdvState.company?.color_scheme.secondary_color ? '#FFF' : '#808080'}
            strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
            stroke={props.rdvState.company?.color_scheme.secondary_color ? '#FFF' : '#808080'} strokeLinecap="round" strokeWidth="2"/>
  </g>
  </svg>)



/*



            <div className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>
                <Row className={'r'}>
                    <Col sm={2} lg={2}>
                        <button hidden={props.rdvState?.back_button == 1 } className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><BackIcon/>{t('back')}
                        </button>
                    </Col>
                    <Col className={'p-2'}>
                        <span hidden={props.rdvState.operation === 'appointment'}
                              className={val_isOrange === 1 ? 'orange-body agenceNameOrange agenceName' : 'agenceName'}>{t('welcome_to')} <strong>{props.rdvState.branch?.Name}</strong></span>
                    </Col>
                </Row>
            </div>

            */


  return <div className={'AgentsScreen'} id={'AgentsScreen'}>
    <Row>
      <Col className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>
        <button className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><BackIcon/>{t('back')}</button>
      </Col>
    </Row>
    <Row className={'serviceBox'}>
      <Col>
        <Row>
          <Col sm={5} className={'pt-4'}>
            <span className={'clickMessage'}>{t('select_attendant')}</span><br/>
            <span hidden={!props.rdvState.noAgent} className={'subclickMessage'}>{t('select_attendant_msg')}</span>
          </Col>
        </Row>
        <Row className={'AgentList'}>
          {props.rdvState.agents?.map((a) => {
            return (<Col xs={6} sm={4} key={a.id} className={'mb-4'}><BranchAgent agent={a}/></Col>)
          })}
        </Row>
        <Row hidden={props.rdvState.noAgent}>
          <Col>
            <NoAgent/>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentsScreen);
