import React, { useEffect, useState } from 'react';
import './Availability.css'
import { connect, useDispatch } from "react-redux";
import { Col, Row, Modal, Button, Alert } from "react-bootstrap";
import backIcon from "../../assets/back.svg";
import { useHistory, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrwSvg from '../../assets/next.svg';
import arrwBlackSvg from '../../assets/next_black.svg'
import TimeSlot from "../TimeSlot";
import DaySlot from "../DaySlot";
import * as rdvActions from "../../actions";
import { useTranslation } from "react-i18next";
import ArrowStepper from '../ArrowStepper/ArrowStepper';
import moment from 'moment';
import { GiHazardSign } from 'react-icons/gi'
import back_avail from "../../assets/back_availabilities.svg";
import next_avail from "../../assets/next_availabilities.svg";
import closeErrorAlert from "../../assets/07 Buttons-06 With Icons-Icon Button-01 Default-01 On White.svg";
import errorNext from "../../assets/Error_severe.svg"
import { useMediaQuery } from 'react-responsive'
import * as ckie from "../../cookieFunctions"

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAvailabilities: (b, s, a) => dispatch(rdvActions.getAvailabilities(b, s, a))
    }
}

const Availability = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { apiKey } = useParams();
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [currentDate, setCurrentDate] = useState(moment());


    const isSmallMobile = useMediaQuery({
        query: '(max-width: 480px)'
    })
    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px and max-width: 1023px)'
    })

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })


    const [state, setState] = useState({
        beginDate: moment(),
        endDate: moment().add(isSmallMobile ? 1:5, "days")
    });


    const goBack = () => {
        dispatch({ type: 'PREVIOUS_STEP' });
        dispatch({ type: 'SET_SLOT', payload: null })
        dispatch({ type: 'SET_DATE', payload: null })
        dispatch({ type: 'SET_SERVICE', payload: null })
        // history.push('/' + sessionStorage.getItem('rdv_apikey') + "/service") 
        history.push('/' + ckie.getSpecificCookie('rdv_apikey') + "/service") 

    }
    let operationInCookie = getCookie('currentQueueCookie')
    const handleYes = () => {
        dispatch({ type: 'SET_OPERATION', payload: null })
        dispatch({ type: 'SET_BRANCH', payload: null })
        dispatch({ type: 'SET_SERVICE', payload: null })
        dispatch({ type: 'SET_SLOT', payload: null })
        setShow(false)
        //window.location.href = '/' + props.rdvState.company?.apikey + '/' + props.rdvState.operation + '/' + props.rdvState.branch?.office + '/' + localStorage.getItem('rdv_lang')
    
        if (props.rdvState.source === 'appointment') {
            // window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + sessionStorage.getItem('operationKey')  + '/' + ckie.getSpecificCookie('rdv_lang')
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + ckie.getSpecificCookie('operationKey')  + '/' + ckie.getSpecificCookie('rdv_lang')
        } else {

            if(props.rdvState.operation === "currentqueue"){
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.id  + '/' + ckie.getSpecificCookie('rdv_lang')
      
          }else{
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.office  + '/' + ckie.getSpecificCookie('rdv_lang')
          }
               

        }
        
    }

    function  getCookie(cName){
        if(document.cookie.length == 0)
          return null;
   
        var regSepCookie = new RegExp('(; )', 'g');
        var cookies = document.cookie.split(regSepCookie);
   
        for(var i = 0; i < cookies.length; i++){
          var regInfo = new RegExp('=', 'g');
          var infos = cookies[i].split(regInfo);
          if(infos[0] == cName){
            return unescape(infos[1]);
          }
        }
        return null;
      }


    const handleClose = () => setShow(false);


    const handleCancel = () => {

        setShow(true)
    }
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [prevDiff, setPrevDiff] = useState(null);

    const [prevBtnStatus, setPrevBtnStatus] = useState(true);
    const [nextBtnStatus, setNextBtnStatus] = useState(true);



    const updateDateLabel = (index) => {
        // console.log("index", index);

        const minDate = new Date(
            Math.min(
              ...props.rdvState.availabilities.map(element => {
                return new Date(element.date);
              }),
            ),
        );
        if(minDate instanceof Date && !isNaN(minDate)){
            var endIndex = isSmallMobile ? index + 1 : index + 5;
            if (endIndex > 30){
                endIndex = 30;
                index = isSmallMobile ? 29 : 25;
            }
            setState(state => {
                return {
                    ...state,
                    beginDate: moment(minDate).add(index, "days"),
                    endDate: moment(minDate).add(endIndex, "days")
                }
            });
        }




    }

    const prevSlide = () => {
        setPrevBtnStatus(false);
        nav1.slickPrev();
        const maxDate = new Date(
            Math.max(
              ...props.rdvState.availabilities.map(element => {
                return new Date(element.date);
              }),
            ),
          );
          const diff = moment(maxDate).diff(state.endDate.format('YYYY-MM-DD'), 'days');
        if (moment().diff(state.beginDate) < 0) {
            // var subtract = 6;
            var subtract = isSmallMobile ? 2 : 6;
            if (diff == 0 && prevDiff != null){
                var subtract = prevDiff;
                setPrevDiff(null);
            }
            setState(state => {
                return {
                    ...state,
                    beginDate: state.beginDate.subtract(subtract, "days"),
                    endDate: state.endDate.subtract(subtract, "days")
                }
            });
        }
        setTimeout(()=>{setPrevBtnStatus(true);}, 500);
    }

    const nextSlide = () => {
        setNextBtnStatus(false);
        nav1.slickNext();
        const maxDate = new Date(
            Math.max(
              ...props.rdvState.availabilities.map(element => {
                return new Date(element.date);
              }),
            ),
          );

        const diff = moment(maxDate).diff(state.endDate.format('YYYY-MM-DD'), 'days');
        if (diff > 0) {
            // var add = 6;
            var add = isSmallMobile ? 2 : 6;
            if (diff < add){
                // add = add + diff;
                add = diff;
                setPrevDiff(diff);
            }
            setState(state => {
                return {
                    ...state,
                    beginDate: state.beginDate.add(add, "days"),
                    endDate: state.endDate.add(add, "days")
                }
            });
        }
        setTimeout(()=>{setNextBtnStatus(true);}, 500);
    }

    let arrwUse = arrwSvg;


    useEffect(() => {
        if (props.rdvState.operation == null) {
            dispatch({ type: 'IS_LOADING', payload: true });
            history.push("/noroute")
        }
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {

        if (props.rdvState.operation === 'appointment') {
            dispatch({ type: 'IS_LOADING', payload: true })
            var agent = props.rdvState?.agent == null || props.rdvState?.agent == undefined ? { agent: "0" } : props.rdvState?.agent;
            props.getAvailabilities(props.rdvState.branch, props.rdvState.service, agent)
        } else if (props.rdvState.operation === 'currentqueue' && props.rdvState.service !== null) {
            dispatch({ type: 'IS_LOADING', payload: true })
        } else goBack()

        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        arrwUse = props.rdvState.company?.color_scheme.secondary_color ? arrwBlackSvg : arrwSvg;
    }, [props.rdvState.company?.color_scheme.secondary_color])
    arrwUse = props.rdvState.company?.color_scheme.secondary_color ? arrwBlackSvg : arrwSvg;


    let today = new Date();

    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;


    const goNext = () => {

        if (props.rdvState.slot !== null) {
            dispatch({ type: 'NEXT_STEP' })
            history.push('/' + props.rdvState.company?.apikey + '/informations')
        } else {
            setShowAlert(true)
            window.location.href = "#alertShow";
        }

    }

    return <div className={'Availability'} id={'Availability'}>


        {/* Start of Modal */}
        <Modal style={{ borderRadius: `0` }} show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>{(props.rdvState.operation === 'currentqueue') ? (<div style={{ textSize: `24px`, opacity: `1` }}>{t('about_leave_queue')}</div>) : (
                    <div style={{ font: `normal normal bold 24px/26px Helvetica Neue`, textSize: `24px`, opacity: `1` }}>{t('about_leave_appointment')}</div>)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{(props.rdvState.operation === 'currentqueue') ? (<div style={{ font: `normal normal bold 16px/19px Helvetica Neue` }}>{t('leave_queue_question')}</div>) : (<div style={{ font: `normal normal bold 16px/19px Helvetica Neue` }}>{t('cancel_appointment_question')}</div>)}
            </Modal.Body>
            <Modal.Footer className={'d-flex justify-content-start'}>
                <button style={{ background: `#FFFFFF`, border: `2px solid #000000`, opacity: `1`, textColor: `#000000`, width: `62px`, height: `40px` }} onClick={handleClose}>
                    {t('no')}
                </button>
                <button style={{ border: `none`, background: `#FF7900`, textColor: `#000000`, opacity: `1`, width: `62px`, height: `40px` }} onClick={handleYes}>
                    {t('yes')}
                </button>
            </Modal.Footer>
        </Modal>
        {/* End of Modal */}

        <div id="alertShow">

            {isMobile ?
                <div className="col-12">
                    < h1 style={{ fontWeight: `bold`, marginLeft: `5px`, fontFamily: `Helvetica Neue` }}>{t('book_appointment')}</h1>

                </div>
                :
                <div className="col-12">
                    <  h1 style={{ marginLeft: `60px`, font: `normal normal bold 50px Helvetica Neue` }}>{t('book_appointment')}</h1>
                </div>


            }

            <hr className="col-sm-12 col-lg-12 col-md-12" style={{ marginBottom: `50px`, marginTop: `15px` }}></hr>


            <div className="col" style={{ width: `800px`, marginRight: `auto`, marginLeft: `auto` }}>

                <ArrowStepper />

                <div style={{ font: `normal normal bold 16px/19px Helvetica Neue`, marginTop: `30px`, marginBottom: `40px`, color: `#000000D9`, opacity: `1`, letterSpacing: `-0.13px`, height: `24px` }}>{t('appointment_hour')}</div>

                {showAlert == true ? (<>
                    <div className="d-flex flex-nowrap row justify-content-between" style={{ width: `100%`, height: `58px`, border: `2px solid #CD3C14`, opacity: `1`, marginTop: `30px`, marginBottom: `30px`, marginLeft: `auto`, marginRight: `auto` }}>
                        <div className="d-flex flex-nowrap">
                            <img src={errorNext} style={{ color: `#CD3C14`, marginLeft: `24px`, marginRight: `15px`, width: `30px`, height: `27px`, marginTop: `auto`, marginBottom: `auto` }} />
                            <div style={{ font: `normal normal bold 15px/19px Helvetica Neue`, letterSpacing: `-0.1px`, color: `#000000`, opacity: `1`, marginTop: `auto`, marginBottom: `auto` }}>
                                {t('warningSlot')}
                            </div>
                        </div>

                        <img src={closeErrorAlert} onClick={() => setShowAlert(false)} style={{ marginTop: `auto`, marginBottom: `auto`, marginRight: `23px` }} />
                    </div>
                </>) : null}

                <div className={'d-flex flex-nowrap'}>
                    <div className={'d-flex flex-nowrap'} style={{ font: `normal normal bold 15px/19px Helvetica Neue`, marginTop: `auto`, marginBottom: `auto`, letterSpacing: `-0.1px`, color: `#000000`, opacity: `1`, height: `23px`, marginRight: `10px` }}>
                        {`${state.beginDate.format("D")} - ${state.endDate.format("D")} ${state.endDate.locale(i18n.language).format("MMMM")} ${state.endDate.format("Y")}`}
                    </div>


                    <div className={'d-flex flex-nowrap'} style={{ marginRight: `50px` }}>
                        {prevBtnStatus ? (<img src={back_avail} style={{ marginRight: `10px`, cursor: `pointer` }} onClick={prevSlide} />):(<img src={back_avail} style={{ marginRight: `10px` }} />)}
                        {nextBtnStatus ? (<img src={next_avail} style={{ cursor: `pointer` }} onClick={nextSlide} />):(<img src={next_avail} />)}
                        
                    </div>
                    {isMobile ?
                        null :
                        <div className={'d-flex flex-wrap'} style={{ font: `normal normal bold 16px/19px Helvetica Neue`, letterSpacing: `-0.1px`, color: `#000000`, opacity: `1`, height: `19px`, marginTop: `auto`, marginBottom: `auto` }}>

                            {props.rdvState.branch?.address}
                            {props.rdvState.branch?.Address}
                        </div>
                    }
                </div>

                <br />
            </div>
            <div className="col" style={{ width: `800px`, marginRight: `auto`, marginLeft: `auto` }}>
                <div style={{ border: `solid 1px gray`, maxHeight: 352, overflowY: `auto` }}>
                    <Row>
                        <Col sm={2} ></Col>
                        <Col sm={10} className={'orange-plage'} style={{ maxWidth: '100%' }} >
                            <Row style={{ marginBottom: `-16px`, marginTop: `-10px` }}>
                                <Col>
                                    <Slider
                                        asNavFor={nav2}
                                        focusOnSelect={false}
                                        ref={slider => setNav1(slider)}
                                        infinite={false}
                                        slidesToShow={6}
                                        slidesToScroll={6}
                                        afterChange={(prev, next) => {updateDateLabel(prev)}}
                                        responsive={[{
                                            breakpoint: 1024,
                                            settings: {
                                                slidesToShow: 6,
                                                slidesToScroll: 6,
                                                infinite: false,
                                            }
                                        },
                                        {
                                            breakpoint: 768,
                                            settings: {
                                                slidesToShow: 6,
                                                slidesToScroll: 6,
                                                infinite: false,
                                            }
                                        },
                                        {
                                            breakpoint: 480,
                                            settings: {
                                                slidesToShow: 2,
                                                slidesToScroll: 2,
                                                infinite: false,
                                            }
                                        }]}
                                    >

                                        {props.rdvState.availabilities?.map((a, aa) => (
                                            <div key={aa}>
                                                <DaySlot day={a} />
                                            </div>
                                        ))}

                                        {/* {props.rdvState.availabilities?.map((a, aa) => a.hours.length && <div key={aa}>
                                            <DaySlot day={a} />
                                        </div>
                                        )} */}

                                    </Slider>
                                </Col>
                            </Row>
                            <hr />

                            <Row>
                                <Col>
                                    <Slider
                                        asNavFor={nav1}
                                        ref={slider => setNav2(slider)}
                                        focusOnSelect={false}
                                        infinite={false}
                                        slidesToShow={6}
                                        slidesToScroll={6}
                                        responsive={[{
                                            breakpoint: 1024,
                                            settings: {
                                                slidesToShow: 6,
                                                slidesToScroll: 6,
                                                infinite: false,
                                            }
                                        },
                                        {
                                            breakpoint: 768,
                                            settings: {
                                                slidesToShow: 6,
                                                slidesToScroll: 6,
                                                initialSlide: 6,
                                                infinite: false,
                                            }
                                        },
                                        {
                                            breakpoint: 480,
                                            settings: {
                                                slidesToShow: 2,
                                                slidesToScroll: 2,
                                                infinite: false,
                                            }
                                        }]}
                                    >

                                        {props.rdvState.availabilities?.map((a, aa) => (
                                            <div key={aa} className={a.hours.length ? 'daySlots' : 'daySlots emptySlot'} style={{ borderRadius: `0` }}>
                                                {a.hours?.map((s, ss) => (<TimeSlot key={ss} day={a} slot={s} />))}
                                            </div>
                                        ))}
                                        {/* {props.rdvState.availabilities?.map((a, aa) => (
                                            <div key={aa} className={'daySlots'} style={{ borderRadius: `0` }}>
                                                {a.hours?.map((s, ss) => (<TimeSlot key={ss} day={a} slot={s} />))}
                                            </div>
                                        ))} */}

                                        {/* {props.rdvState.availabilities?.map((a, aa) => a.hours.length &&
                                            <div key={aa} className={'daySlots'} style={{ borderRadius: `0` }}>
                                                {a.hours?.map((s, ss) => (<TimeSlot key={ss} day={a} slot={s} />))}
                                            </div>
                                        )} */}

                                    </Slider>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2} ></Col>
                    </Row>
                </div>

                {/* Start of 3 buttons */}


                {isMobile ?
                    <div style={{ paddingRight: `20px`, paddingLeft: `20px`, marginTop: `30px` }}>
                        <Row className={'d-flex justify-content-between flex-nowrap'} style={{ marginBottom: `10px` }}>
                            <button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FFFFFF`, borderColor: `#000000`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }} onClick={handleCancel}>
                                {t('cancel')}
                            </button>
                            <button style={{
                                font: `normal normal bold 16px/19px Helvetica Neue`,
                                backgroundColor: `#000000`, textTransform: `capitalize`, color: `#FFFFFF`, opacity: `1`, height: `40px`, paddingRight: `20px`, paddingLeft: `20px`, border: `2px solid #000`,
                                borderRadius: `0px`
                            }} onClick={() => {
                                goBack();
                            }}>{t('back')}</button>

                        </Row>
                        <Row><button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FF7900`, border: `none`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, width: `100%`, height: `40px` }}
                            onClick={goNext}>{t('next')}</button></Row>
                    </div>
                    :
                    <Row className="d-flex justify-content-between" style={{ paddingTop: `40px` }}>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FFFFFF`, borderColor: `#000000`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }} onClick={handleCancel}>
                                {t('cancel')}
                            </button>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} className="d-flex justify-content-end">
                            <button style={{
                                font: `normal normal bold 16px/19px Helvetica Neue`,
                                backgroundColor: `#000000`, textTransform: `capitalize`, color: `#FFFFFF`, opacity: `1`, height: `40px`, paddingRight: `20px`, paddingLeft: `20px`, border: `2px solid #000`, marginRight: `12px`,
                                borderRadius: `0px`
                            }} onClick={() => {
                                goBack();
                            }}>{t('back')}</button>
                            <button style={{ font: `normal normal bold 16px/19px Helvetica Neue`, backgroundColor: `#FF7900`, border: `none`, opacity: `1`, color: `#000000`, textTransform: `capitalize`, paddingLeft: `20px`, paddingRight: `20px`, height: `40px` }}
                                onClick={goNext}>{t('next')}</button>

                        </Col>
                    </Row>
                }
                {/* End of 3 buttons */}



            </div>
        </div>
    </div >;
};

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
