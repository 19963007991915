import React, {useEffect, useState} from 'react';
import './LoadingAnimation.css'
//import loader from '../../assets/loader.svg'
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
      rdvState: state
    }
}




const LoadingAnimation = (props) => {
    const [loaderColor, setLoaderColor] = useState();
    const [loaderColorExist, setLoaderColorExist] = useState();

//var loaderColor = 'rgb(153,153,153)';

useEffect(() => {
    //setLoaderColorExist(false);
    if(props.rdvState.company === null){
        setLoaderColor('#999');
        //setLoaderColorExist(true);
    }else {
        setLoaderColor('rgb(255,102,0)');
        //setLoaderColorExist(true);
    }
    
    }, [props.rdvState.company])


/*
    var loaderColor = 'rgb(153,153,153)';


    console.log(loaderColor);
    useEffect(() => {
    }, [])



        if(props.rdvState.company?.color_scheme.isOrange !== undefined && props.rdvState.company?.color_scheme.isOrange == 1){
          loaderColor = 'rgb(255,102,0)';
          console.log(loaderColor);
        }else if(props.rdvState.company !== undefined) {
          loaderColor = '#0089e1';
          console.log(loaderColor);
        }else{
          loaderColor = 'rgb(153,153,153)';
          console.log(loaderColor);
        }
*/

    //loaderColor = "#000";
//{loaderColorExist == true ? {borderLeftColor: loaderColor} : {borderLeftColor: ''}} 


    return <div className={props.isLoading ? 'LoadingAnimation isLoading' : 'LoadingAnimation notLoading'}
                id={'LoadingAnimation'}>
        <div style={{ borderLeftColor: loaderColor !== undefined ? loaderColor : '#999999' }} 
        className={'loader'}></div>
    </div>;
};

export default connect(mapStateToProps)(LoadingAnimation);
