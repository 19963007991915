import React from 'react';
import './OperationSelector.css'
import {connect, useDispatch} from "react-redux";
import * as rdvActions from "../../actions";
import { useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { GoCheck } from "react-icons/go";
import { BiCalendar } from "react-icons/bi";
import * as ckie from "../../cookieFunctions"

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBranches: e => dispatch(rdvActions.getBranches(e))
  }
}
const OperationSelector = (props) => {
  let history = useHistory();
  const {t} = useTranslation();
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  let dispatch = useDispatch();
  const selected = props.rdvState.operation ? props.rdvState.operation : ckie.getSpecificCookie('rdv_prev_op') ? ckie.getSpecificCookie('rdv_prev_op') : null
  


  var SelectedIcon = (p) => (
    <svg style={{marginLeft: 15, marginRight: 10}} xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
      <g id="Group_1868" data-name="Group 1868" transform="translate(-966 -320)">
        <g id="Ellipse_115" data-name="Ellipse 115" transform="translate(966 320)" fill="none" stroke="#e4e4e4"
           strokeWidth="1">
          <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
          <circle cx="11.5" cy="11.5" r="11" fill="none"/>
        </g>
        <g id="Group_3496" data-name="Group 3496">
          <circle id="Ellipse_115-2" data-name="Ellipse 115" cx="11.5" cy="11.5" r="11.5"
                  transform="translate(966 320)" fill="#fff" stroke={p.stroke}/>
          <path id="Path_2890" data-name="Path 2890" d="M0,2.054,2.745,8.132,14.244,0"
                transform="translate(970.588 328.236) rotate(-7)" fill="none" stroke={p.color} strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="2"/>
        </g>
      </g>
    </svg>
)

  const handleClick = () => {
    if(props.rdvState.branch) {
      dispatch({type: 'IS_LOADING', payload: true});
    dispatch({type: 'SET_OPERATION', payload: props.label});
    // localStorage.setItem('rdv_prev_op', props.label);
    ckie.setSpecificCookie("rdv_prev_op", props.label)
    props.getBranches(props.label);
    history.push('/' + props.rdvState.company?.apikey + "/service");

    } else { alert(`${t('warningBranch')}`)}
    
  }

/*

      <span className={val_isOrange === 1 ? 'orange-header' : ''} style={{color: props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : ''}}>{props.text}</span>
      */

  return <div className={val_isOrange === 1 ? 'OperationSelectorOrange' : 'OperationSelector'} id={'OperationSelector'}>
    <a className={selected === props.label ? 'Selected' : ''} 
    // style={{backgroundColor: selected === props.label ? props.rdvState.company?.color_scheme.primary_color : ''}} 
    onClick={handleClick}
    >
      

      {/* <span hidden={val_isOrange !== 1}>
      {selected === props.label ? (<SelectedIcon color={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#1689e1'}/>) : null}
          </span> */}

      <span style={{marginRight: `20px`}}><BiCalendar style={{height: `25px`, width: `25px`}} /></span>

      <span style={{marginRight: `80px`}}>{props.text}</span>
      <span>{props.rdvState.operation !== null && props.rdvState.operation == props.operation ? <GoCheck style={{height: `25px`, color: `#32C832`}}  />: null}</span>
      {/* <span hidden={val_isOrange === 1}> */}
      <span>
      {selected === props.label ? (<GoCheck style={{height: `25px`, color: `#32C832`}}/>) : (
          null)}
          </span>
    </a>
    {' '}
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationSelector);
