import React, {useState, useEffect} from 'react';
import backIcon from "../../assets/back.svg";
import { Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import serviceSvg from '../../assets/service.svg'
import branchSvg from '../../assets/branch.svg'
import locationSvg from '../../assets/slocation.svg'
import dateSvg from '../../assets/date.svg'
import timeSvg from '../../assets/time.svg'
import countries from "../../datas/countries";
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import moment from "moment";
import * as rdvActions from "../../actions";
import NameInitials from "../NameInitials";
import { useTranslation } from "react-i18next";
// import "../../../node_modules/awesome-react-steps/lib/css/arrows.css";
import "./ArrowStepper.css";
import { Arrows, StepsModel } from "awesome-react-steps";
import * as ckie from "../../cookieFunctions"


const mapStateToProps = (state) => {
  return {
      rdvState: state
  } 
}

const mapDispatchToProps = (dispatch) => {
  return {
      getServices: e => dispatch(rdvActions.getServices(e),)
  }

}

const ArrowStepper = (props) => { 
  const {t} = useTranslation();
  let model, model2;

  var stepTab_val = [];
  var stepTab2_val = [];

  // if (localStorage.getItem("list1")){
  if (ckie.getSpecificCookie("list1")){
    // console.log("cook", ckie.getSpecificCookie("list1"))
    stepTab_val = JSON.parse(decodeURI(ckie.getSpecificCookie("list1")));
    
  }
  if (ckie.getSpecificCookie("list2")){
    stepTab2_val = JSON.parse(decodeURI(ckie.getSpecificCookie("list2")));
  }
  const [stepTab, setStepTab] = useState(stepTab_val);
  const [stepTab2, setStepTab2] = useState(stepTab2_val);


  useEffect(() => {
    if(props.rdvState.showClientType){
      var showType_list = [t('client_type'), t('appointment_reason'), t('availability'), t('contact'), t('summary')];
      
      var showType_list2 = [t('client_type'), t('visit_reason'), t('contact'), t('summary')];

      // localStorage.setItem("list1", JSON.stringify(showType_list));
      // localStorage.setItem("list2", JSON.stringify(showType_list2));
      ckie.setSpecificCookie("list1", encodeURI(JSON.stringify(showType_list)))
      ckie.setSpecificCookie("list2", encodeURI(JSON.stringify(showType_list2)))
      setStepTab(showType_list);
      setStepTab2(showType_list2);
      

    }else{
      var hideType_list = [t('appointment_reason'), t('availability'), t('contact'), t('summary')];
      
      var hideType_list2 = [t('visit_reason'), t('contact'), t('summary')];

      // localStorage.setItem("list1", JSON.stringify(hideType_list));
      // localStorage.setItem("list2", JSON.stringify(hideType_list2));
      // console.log("Test", JSON.stringify(hideType_list))
      ckie.setSpecificCookie("list1", encodeURI(JSON.stringify(hideType_list)))
      ckie.setSpecificCookie("list2", encodeURI(JSON.stringify(hideType_list2)))
      setStepTab(hideType_list);
      setStepTab2(hideType_list2);
      
        
    }

  }, [props.rdvState.showClientType])
  
    
    const [stepIndex, setStepIndex] = useState(0)

    if(props.rdvState.showClientType){
      model = new StepsModel(
        {steps: [{ label: stepTab[0] }, { label: stepTab[1] }, { label: stepTab[2] }, { label: stepTab[3] }, { label: stepTab[4]}],
          current: props.rdvState.currentStepIndice}
        );
         model2 = new StepsModel(
          {steps: [{ label: stepTab2[0] }, { label: stepTab2[1] }, { label: stepTab2[2] }, { label: stepTab2[3]}],
            current: props.rdvState.currentStepIndice}
          );
    }else{
      model = new StepsModel(
        {steps: [{ label: stepTab[0] }, { label: stepTab[1] }, { label: stepTab[2] }, { label: stepTab[3] }],
          current: props.rdvState.currentStepIndice}
        );
         model2 = new StepsModel(
          {steps: [{ label: stepTab2[0] }, { label: stepTab2[1] }, { label: stepTab2[2] }],
            current: props.rdvState.currentStepIndice}
          );
    }
    
    
      
        if (props.rdvState.operation === 'currentqueue') {
          return <div className={'container'}> 
          
                  <Arrows
                    model={model2}
                    />
                </div>;
        }else if (props.rdvState.operation === 'appointment') {
          return <div>
                  <Arrows
                    model={model}
                      />
                </div>;
    
        } else { return null}


};


export default connect(mapStateToProps, mapDispatchToProps) (ArrowStepper);
