import React,{ useState} from 'react';
import './CovidMessage.css'
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux';
import { Alert } from 'react-bootstrap';
import { RiErrorWarningFill } from 'react-icons/ri'

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const CovidMessage = (props) => {
  
  const {t} = useTranslation();
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert
      className={val_isOrange === 1 ? 'orange-header CovidMessage CovidMessage-orange' : 'CovidMessage'} id={'CovidMessage'}
        variant="" onClose={() => setShow(false)} dismissible>
        <Alert.Heading style={{color: `black`}}><RiErrorWarningFill style={{marginRight: `5px`, color: `#FFCC00`}}/>{t('info_coronavirus')}</Alert.Heading>
        <p className={val_isOrange === 1 ? 'orange-header' : ''} style ={{color: props.rdvState.company?.color_scheme.secondary_color, marginLeft: `30px`}}>
        {t('covid_msg')}
        </p>
      </Alert>
    );
  }else return null;

};

export default connect(mapStateToProps)(CovidMessage);
