import React, {useEffect} from 'react';
import './BranchScreen.css';
import {Col, Row, Table} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import moment from "moment";
import ArrowStepper from '../ArrowStepper/ArrowStepper';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {

  return {}
}

const BranchScreen = (props) => {

  const { t, i18n } = useTranslation();
    const { apiKey } = useParams();
    let history = useHistory();
    i18next.on('languageChanged', function (lng) {
        moment.locale(lng);
    });
    
    let dispatch = useDispatch();

  return <div>

          <Row>                 
                <div style={{ left: `0px` }} className="col-sm-12 col-lg-12 col-md-12">
                    { (props.rdvState.operation === 'currentqueue') ? (<h1 style={{textSize:`50px`, fontFamily: `Helvetica Neue`}}>{t('join_the_current_queue')}</h1>) : (
                    <h1 style={{textSize:`50px`, fontFamily: `Helvetica Neue`}}>{t('book_appointment')}</h1>)}  

                <hr className="col-sm-12 col-lg-12 col-md-12" />
                </div>      
           </Row>
          <ArrowStepper style={{marginTop: `40px`}}/>

          <Row>
                        <Col md={4}>
                          
                            
                        </Col>
                        <Col md={8}></Col>
                        <Col md={4}>
                           
                        </Col>
                    </Row>

  

  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchScreen);
