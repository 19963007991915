import React, { useEffect, useState } from 'react';
import './RedirectService.css'
import { useParams, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as rdvActions from "../../actions";
import bg from '../../assets/background.jpg'
import ChartData from '../../components/ChartData';
import {useTranslation} from 'react-i18next';
import i18next from "i18next";
import * as ckie from "../../cookieFunctions"

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBranches: e => dispatch(rdvActions.getBranches(e))
    }
}
const RedirectService = (props) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const { operation, apiKey, officeId, lang,rdvNum } = useParams();
    const getQueryParams = function(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    const source = getQueryParams("source", window.location.href);
    const [company, setCompany] = useState();
    const [branches, setBranches] = useState();
    const [branch, setBranch] = useState();

  const {t, i18n} = useTranslation();
    useEffect(() => {
        // sessionStorage.setItem('rdv_apikey', apiKey)
        ckie.setSpecificCookie('rdv_apikey', apiKey)
        dispatch({ type: 'SET_OPERATION', payload: operation });
        ckie.setSpecificCookie("rdv_prev_op",operation)
        // localStorage.setItem('rdv_prev_op', operation);
        if(!(lang === "fr" || lang === "en")) {
            lang="en"
        }
        if (source && ["appointment", "currentqueue"].indexOf(source) !== -1){
            dispatch({ type: 'SET_URL_SOURCE', payload: source });
        }
        i18n.changeLanguage(lang)
        // localStorage.setItem("rdv_lang", lang);
        ckie.setSpecificCookie("rdv_lang", lang)

    }, []);

    
    useEffect(() => {
        if (branches == true) {
            var branchList = props.rdvState.branches;
            var currentBranch = branchList.filter(function (branch) {
                if (branch.office == undefined) {
                    return branch.id == officeId;
                }
                else {
                    return branch.office == officeId;
                }
            });
            // localStorage.setItem('rdv_prev_brach', JSON.stringify({ o: operation, b: currentBranch[0] }))
            ckie.setSpecificCookie("rdv_prev_brach",JSON.stringify({ o: operation, b: currentBranch[0] }))
            dispatch({ type: 'SET_BRANCH', payload: currentBranch[0] })
        }
    }, [branches]);

    useEffect(() => {
        if (branch == true) {
            dispatch({ type: 'SET_NO_BACK_BUTTON', payload: 1 })
            if (operation === "graph") {
                dispatch({ type: 'IS_LOADING', payload: false });
                history.push('/' + apiKey + "/graph");
            } else {
                history.push('/' + apiKey + "/service");
                // history.push('/client_type');
            }
        }
    }, [branch]);

    useEffect(() => {
        if (company == true) {
            dispatch({ type: 'IS_LOADING', payload: true });
            if(operation !== "graph") {
                props.getBranches(operation);
            } else {
                dispatch({ type: 'IS_LOADING', payload: false });
            }
        }
    }, [company]);

    useEffect(() => {
        if (props.rdvState.company) {
            setCompany(true);
        }
        if (props.rdvState.branches && props.rdvState.branches.length > 0) {
            setBranches(true);
        }
        if (props.rdvState.branch) {
            setBranch(true);
        }
    });

    return (operation === "graph") && <ChartData />; 
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectService);
