import 'persist-js';

var persistence = new window.Persist.Store('SWL')


const setSpecificCookie = (cname, cvalue) => {
  persistence.set(cname, cvalue);
}
  
const getSpecificCookie = (cname) => {
  return persistence.get(cname);
}
  

export { getSpecificCookie, setSpecificCookie } 