import axios from "axios";
import * as ckie from "../cookieFunctions"
  

export function addArticle(payload) {
    return {type: "ADD_ARTICLE", payload}
}

export function setSumBtnActive(payload) {
    return {type: "ACTIVE_SUMMARY_BTN", payload}
}
export function setOperation(payload) {
    return {type: "SET_OPERATION", payload}
}

export function setBranch(payload) {
    return {type: "SET_BRANCH", payload}
}

export function setService(payload) {
    return {type: "SET_SERVICE", payload}
}

export function setServiceClosed(payload) {
    return {type: "SET_SERVICE_CLOSED", payload}
}
 
export function setVisitor(payload) {
    return {type: "SET_VISITOR", payload}
}
export function setCustomerType(payload) {
    return {type: "SET_CUSTOMER_TYPE", payload}
}

export function getRdvData(rid) {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "Session_RDVQ=eljhu6va9ibag0aqc0n58ivn12");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return function (dispatch) {
        // fetch("https://booking.right-q.com/596045660/5/rt/getrdv/key/" + sessionStorage.getItem('rdv_apikey') + "/rdvnum/" + rid, requestOptions)
        fetch("https://booking.right-q.com/596045660/5/rt/getrdv/key/" + ckie.getSpecificCookie('rdv_apikey') + "/rdvnum/" + rid, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch({type: "GET_RDV_DATA", payload: result.data})
                dispatch({type: 'IS_LOADING', payload: false});
            })
            .catch(error => {
                // console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };
}

export function getTicket(data) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };


    return function (dispatch) {
        fetch("https://booking.right-q.com/rdv/joinqueuenow/iswl/1", requestOptions)
            .then(response => 
                response.json())
            .then(result => {
                dispatch({type: "GET_TICKET", payload: result.data})
                dispatch({type: "IS_LOADING", payload: false})
            })
            .catch(error => {
                // console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };
}

export function getRdv(data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("fullname", data.fullname);
    urlencoded.append("mobile", data.mobile);
    urlencoded.append("code_country", data.code_country);
    urlencoded.append("email", data.email);
    urlencoded.append("bookdate", data.bookdate);
    urlencoded.append("comment", data.comment);
    urlencoded.append("language", data.language);
    urlencoded.append("note", data.note);
    urlencoded.append("customertype", data.customertype);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    

    return function (dispatch) {
        if(!data.agent){data.agent=0;}
        // fetch("https://booking.right-q.com/596045660/5/rt/save/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + data.branch + "/service/" + data.service + "/agent/" + data.agent  + "/isswl/" + 1 + "/language/" + data.language, requestOptions)
        fetch("https://booking.right-q.com/596045660/5/rt/save/key/" + ckie.getSpecificCookie('rdv_apikey') + "/branch/" + data.branch + "/service/" + data.service + "/agent/" + data.agent  + "/isswl/" + 1 + "/language/" + data.language, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                    dispatch({type: 'GET_RDV', payload: result.data});

                } else {
                    // console.log(result.message)
                }
                dispatch({type: 'IS_LOADING', payload: false});
            })
            .catch(error => {
                // console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };

}

export function getAvailabilities(branch, service, agent) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Cookie", "Session_RDVQ=eljhu6va9ibag0aqc0n58ivn12");

    var urlencoded = new URLSearchParams();
    // Getting current date
    var today = new Date();
    var todayDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' 00:00:00';
    // Getting end date (Current date + 1 month)
    var twomonths = new Date();
    twomonths.setMonth(twomonths.getMonth() + 1);
    var twoMonthsDate = twomonths.getFullYear()+'-'+(twomonths.getMonth()+1)+'-'+twomonths.getDate() + ' 00:00:00';
    urlencoded.append("date1", todayDate);
    urlencoded.append("date2", twoMonthsDate);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    return function (dispatch) {
        // fetch("https://booking.right-q.com/596045660/5/rt/hoursswl/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + branch.office + "/service/" + service.service + "/agent/" + agent.agent + "/isswl/" + 1, requestOptions)
        fetch("https://booking.right-q.com/596045660/5/rt/hoursswl/key/" + ckie.getSpecificCookie('rdv_apikey') + "/branch/" + branch.office + "/service/" + service.service + "/agent/" + agent.agent + "/isswl/" + 1, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch({type: "SET_AVAILABILITIES", payload: result.data})
                dispatch({type: 'IS_LOADING', payload: false})
            })
            .catch(error => {
                // console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };
}









export function getAvailabilities1(branch, service, agent) {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Cookie", "Session_RDVQ=eljhu6va9ibag0aqc0n58ivn12");

    var urlencoded = new URLSearchParams();
    // Getting current date
    var today = new Date();
    var todayDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' 00:00:00';
    // Getting end date (Current date + 1 month)
    var twomonths = new Date();
    twomonths.setMonth(twomonths.getMonth() + 1);
    var twoMonthsDate = twomonths.getFullYear()+'-'+(twomonths.getMonth()+1)+'-'+twomonths.getDate() + ' 00:00:00';
    urlencoded.append("date1", todayDate);
    urlencoded.append("date2", twoMonthsDate);


    var config = {
      method: 'post',
      url: 'https://booking.right-q.com/596045660/5/rt/hoursswl/key/' +  + ckie.getSpecificCookie('rdv_apikey') + '/branch/' + branch.office + '/service/' + service.service + '/agent/' + agent.agent + '/isswl/' + 1,
      headers: myHeaders,
      data : urlencoded
    };
    
    return function (dispatch) {
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      dispatch({type: "SET_AVAILABILITIES", payload: response.data})
      dispatch({type: 'IS_LOADING', payload: false})
    })
    .catch(function (error) {
    //   console.log(error);
      dispatch({type: 'IS_LOADING', payload: false});
    });
    
    }




}






export function getCustomerType(lang, apiKey) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Cookie", "Session_RDVQ=eljhu6va9ibag0aqc0n58ivn12");

    var urlencoded = new URLSearchParams();
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };
    return (dispatch) => {
        fetch("https://booking.right-q.com/596045660/5/rt/customer_types/key/" + apiKey + "/language/" + lang)
        .then(response => response.json())
        .then(result => {
            dispatch({type: "SET_CUSTOMERS_TYPES", payload: result.data})
            dispatch({type: 'IS_LOADING', payload: false})
        })
        .catch(error => {
            // console.log('error', error)
            dispatch({type: 'IS_LOADING', payload: false});
        });

    }
}

export function setAgent(payload) {
    return {type: "SET_AGENT", payload}
}

export function getAgents(service, branch) {

    if (service.service !== undefined) {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        return function (dispatch) {
            // fetch("https://booking.right-q.com/596045660/5/rt/agents/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + branch.office + "/service/" + service.service, requestOptions)
            fetch("https://booking.right-q.com/596045660/5/rt/agents/key/" + ckie.getSpecificCookie('rdv_apikey') + "/branch/" + branch.office + "/service/" + service.service, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 404) {
                        dispatch({type: "SET_AGENTS", payload: []});
                        dispatch({type: "SET_NO_AGENTS", payload: false});
                    } else {
                        dispatch({type: "SET_NO_AGENTS", payload: true});
                        dispatch({type: "SET_AGENTS", payload: result.data});
                    }

                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    // console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        };
    }

}

export function setSDay(payload) {
    return {type: "SET_DATE", payload}
}

export function setSlot(payload) {
    return {type: "SET_SLOT", payload}
}

export function getServices(branch) {

    if (branch.office !== undefined) {
        let xmyHeaders = new Headers();

        let xrequestOptions = {
            method: 'GET',
            headers: xmyHeaders,
            redirect: 'follow'
        };

        return function (dispatch) {
            // fetch("https://booking.right-q.com/596045660/5/rt/servicesswl/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + branch.office +"/isswl/" + 1, xrequestOptions)
            fetch("https://booking.right-q.com/596045660/5/rt/servicesswl/key/" + ckie.getSpecificCookie('rdv_apikey') + "/branch/" + branch.office +"/isswl/" + 1, xrequestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.length <= 0) {
                        dispatch({type: "SET_SERVICES", payload: []});
                        dispatch({type: "SET_SERVICES_CLOSED", payload: result.data});
                    }
                    if (result.data.length > 0) {
                        dispatch({type: "SET_SERVICES", payload: result.data});
                        dispatch({type: "SET_SERVICES_CLOSED", payload: null});
                    }
                    //dispatch({type: "SET_SERVICES", payload: result.data});
                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    // console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        };
    }
    if (branch.id !== undefined) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return function (dispatch) {
            // fetch("https://booking.right-q.com/rdv/services?apikey=" + sessionStorage.getItem('rdv_apikey') + "&office=" + branch.id + "&isswl=" + 1, requestOptions)
            fetch("https://booking.right-q.com/rdv/services?apikey=" + ckie.getSpecificCookie('rdv_apikey') + "&office=" + branch.id + "&isswl=" + 1, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "OFFICE_CLOSED") {
                        dispatch({type: "SET_SERVICES", payload: []});
                        dispatch({type: "SET_SERVICES_CLOSED", payload: result.data});
                    }
                    if (result.message === "SERVICES_FOUND") {
                        dispatch({type: "SET_SERVICES", payload: result.data});
                        dispatch({type: "SET_SERVICES_CLOSED", payload: null});
                    }
                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    // console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        }
    }
}

export function getCompany(apiKey) {
    var myHeaders = new Headers();
    // myHeaders.append("Access-Control-Allow-Credentials", true);
    // myHeaders.append("Access-Control-Allow-Origin", "localhost:3001");
    // myHeaders.append("Access-Control-Allow-Headers", "*");
    // myHeaders.append("Access-Control-Allow-Methods", "*");
    myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");
    // myHeaders.append("test", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");
    //myHeaders.append("Access-Control-Allow-Origin", "*")
    //myHeaders.append("Access-Control-Allow-Headers", "*")
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return function (dispatch) {
        fetch("https://booking.right-q.com/rdv/settings?apikey=" + apiKey, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (apiKey !== undefined && result.status === 200) {
                    dispatch({type: "SET_COMPANY", payload: result.data.setting});
                    dispatch({type: 'IS_LOADING', payload: false});
                    dispatch({type: 'APIKEY', payload: apiKey});
                    // sessionStorage.setItem('rdv_apikey', apiKey)
                    ckie.setSpecificCookie('rdv_apikey', apiKey)
                } else {
                    dispatch({type: 'APIKEY', payload: false});
                    dispatch({type: 'IS_LOADING', payload: false});
                }
            })
            .catch(error => {
                // console.log('error', error)
                //dispatch({type: 'IS_LOADING', payload: false});
            });
    }
}

export function getBranches(o) {

    function getABranches() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        return function (dispatch) {
            // fetch("https://booking.right-q.com/596045660/5/rt/branch/" + sessionStorage.getItem('rdv_apikey') + "", requestOptions)
            fetch("https://booking.right-q.com/596045660/5/rt/branch/" + ckie.getSpecificCookie('rdv_apikey') + "", requestOptions)
                .then(response => response.json())
                .then(result => {
                    dispatch({type: "SET_BRANCHES", payload: result.data});
                    dispatch({type: "SET_BRANCH", payload: null});
                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    // console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        }
    }

    function getQBranches() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return function (dispatch) {
            // fetch("https://booking.right-q.com/rdv/offices?apikey=" + sessionStorage.getItem('rdv_apikey') + "", requestOptions)
            fetch("https://booking.right-q.com/rdv/offices?apikey=" + ckie.getSpecificCookie('rdv_apikey') + "", requestOptions)
                .then(response => response.json())
                .then(result => {
                    dispatch({type: "SET_BRANCHES", payload: result.data.office});
                    //dispatch({type: "SET_COMPANY", payload: result.data.setting});
                    dispatch({type: "SET_BRANCH", payload: null});
                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    // console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        }
    }

    if (o === 'currentqueue') {
        return getQBranches()
    }
    if (o === 'appointment') {
        return getABranches()
    }
}

export function getData() {
    return function (dispatch) {
        return axios.get('https://jsonplaceholder.typicode.com/posts')
            .then(json => {
                dispatch({type: "DATA_LOADED", payload: json.data});
                document.getElementById("loadingContainer").classList.remove("showLoading");
                //window.location = 'https://google.com';
            });
    };
}

export function get() {
    return function (dispatch) {

    };
}

export function getWaitingTime(apiKey, officeId) {
    return function (dispatch) {
        let url = `https://api-rdv.right-com.com/get-waiting-time/${apiKey}/${officeId}`;
        return axios.get(url)
            .then(json => {
                //console.log("json", json.data.results.datas);
                return json.data.results.datas;
            })
            .catch(error => {
                // console.log('error', error);
            });
    };

}

export function getContact(apiKey, officeId, journey) {
    return function (dispatch) {
        let url = `https://api-rdv.right-com.com/contact/${apiKey}/${officeId}/${journey}`;
        return axios.get(url)
            .then(json => {
                //console.log("json", json.data.results.datas);
                return json.data.results.datas;
            })
            .catch(error => {
                console.log('error', error);
            });
    };

}
export function fromAppointmentToCurrentQueue(apiKey, officeId) {
    return function (dispatch) {
        let url = `https://api-rdv.right-com.com/switch/${apiKey}/${officeId}`;
        return axios.get(url)
            .then(json => {
                //console.log("json", json.data.results.datas);
                return json.data.results.datas;
            })
            .catch(error => {
                // console.log('error', error);
            });
    };

}
 
export function getRDVInformation(apiKey, rdvNum) {
    return function (dispatch) {
        let url = `https://booking.right-q.com/596045660/5/rt/getrdv/key/${apiKey}/rdvnum/${rdvNum}`;
        return axios.get(url)
        .then(json => {
            return json.data.results.datas;
        })
        .catch(error => {
            // console.log('error', error);               
        })
    }
}

export function cancelRDV(apiKey, rdvNum) {
    return function (dispatch) {
        let url = `https://booking.right-q.com/596045660/5/rt/cancelrdv/key/${apiKey}/rdvnum/${rdvNum}`;
        return axios.get(url)
        .then(json => {
            return json.data.results.datas;
        })
        .catch(error => {
            // console.log('error', error);
        })
    }
}