import React, { useEffect, useState } from "react";
import "./InfosForm.css";
import backIcon from "../../assets/back.svg";
import {
  Col, Dropdown, DropdownButton, Form, FormControl, InputGroup,
  Row,
  Alert,
  Modal,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import serviceSvg from "../../assets/service.svg";
import branchSvg from "../../assets/branch.svg";
import locationSvg from "../../assets/slocation.svg";
import dateSvg from "../../assets/date.svg";
import timeSvg from "../../assets/time.svg";
import countries from "../../datas/countries";
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import moment from "moment";
import * as rdvActions from "../../actions";
import NameInitials from "../NameInitials";
import { useTranslation } from "react-i18next";

import { GiHazardSign } from "react-icons/gi";
import { FcInfo } from "react-icons/fc";

import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import ArrowStepper from "../ArrowStepper/ArrowStepper";
import { useMediaQuery } from "react-responsive";
import errorNext from "../../assets/Error_severe.svg";
import * as ckie from "../../cookieFunctions";
import { FaGlasses } from "react-icons/fa";

const mapStateToProps = (state) => {
  return {
    rdvState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTicket: (e) => dispatch(rdvActions.getTicket(e)),
    getRdv: (e) => dispatch(rdvActions.getRdv(e)),
    getContact: (a, o, j) => dispatch(rdvActions.getContact(a, o, j)),
    getCustomerType: (l, a) => dispatch(rdvActions.getCustomerType(l, a),),
  };
};

const InfosForm = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { apiKey } = useParams();
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  i18next.on("languageChanged", function (lng) {
    moment.locale(lng);
  });
  const [country, setCountry] = useState(
    countries.filter((c) => {
      //return c.alpha2Code === props.rdvState.company?.Country
      return c.iso2 === props.rdvState.company?.Country.toLowerCase();
    })[0]
  );
  let o;
  let a = props.rdvState.apiKey;
  let j = props.rdvState.operation;
  if (j === "appointment") {
    o = props.rdvState.branch.office;
  } else {
    o = props.rdvState.branch.id;
  }

  useEffect(() => {
    if (
      typeof a !== "undefined" &&
      typeof o !== "undefined" &&
      j !== "undefined"
    ) {
      props.getContact(a, o, j).then((response) => {
        // console.log(response);
      });
    }
  }, [a, o, j]); 
  
  useEffect(() => {    
    if (ckie.getSpecificCookie("rdv_lang") !== null) {
        dispatch({ type: 'IS_LOADING', payload: true });
        props.getCustomerType(ckie.getSpecificCookie("rdv_lang"), apiKey)
    }
    // console.log("props.rdvState ===> ", props.rdvState)
}, [ckie.getSpecificCookie("rdv_lang")])

  const [phone, setPhone] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [phoneValid, setPhoneValid] = useState(null);
  const [phoneIsInValid, setPhoneIsInValid] = useState(null);
  const [phoneErrMsg, setPhoneErrMsg] = useState(t("phone_form_error"));

  
  const [customerTypeError, setCustomerTypeError] = useState(false);
  const [customerTypeDefined, setCustomerTypeDefined] = useState(false);

  const [name, setName] = useState();
  const [nameValid, setNameValid] = useState(null);
  const [nameIsInValid, setNameIsInValid] = useState(null);
  const [nameErrMsg, setNameErrMsg] = useState(t("name_form_error"));
  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState(null);
  const [emailIsInValid, setEmailIsInValid] = useState(null);
  const [emailErrMsg, setEmailErrMsg] = useState(t("email_form_error"));

  const [note, setNote] = useState();
  const [noteValid, setNoteValid] = useState(null);
  const [noteIsInValid, setNoteIsInValid] = useState(null);
  const [noteErrMsg, setNoteErrMsg] = useState("");

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px and max-width: 1023px)",
  });

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const filterCountries = (e) => {
    if (e.target.value.length > 2) {
      let fc = setFilteredCountries(
        countries.filter((c) => {
          return (
            c && c.name.toLowerCase().includes(e.target.value.toLowerCase())
          );
        })
      );
    } else setFilteredCountries(countries);
  };

  const countryFlag = (
    <span>
      <img
        style={{ height: 14 }}
        src={"/countries/" + country?.iso2.toLowerCase() + ".svg"}
        alt={""}
      />
      <small style={{ fontSize: 14, paddingLeft: 10 }}>
        {" "}
        +{country?.callingCodes[0]}
      </small>
    </span>
  );
  const goBack = () => {
    dispatch({ type: "PREVIOUS_STEP" });
    if (props.rdvState.operation === "currentqueue") {
      dispatch({ type: "SET_VISITOR", payload: null });
      dispatch({ type: "SET_SERVICE", payload: null });
      // history.push("/" + sessionStorage.getItem("rdv_apikey") + "/service");
      history.push("/" + ckie.getSpecificCookie("rdv_apikey") + "/service");
    } else {
      dispatch({ type: "SET_VISITOR", payload: null });
      dispatch({ type: "SET_SLOT", payload: null });
      dispatch({ type: "SET_DATE", payload: null });
      dispatch({ type: "SET_CUSTOMER_TYPE", payload: null });
      history.push(
        // "/" + sessionStorage.getItem("rdv_apikey") + "/availability"
        "/" + ckie.getSpecificCookie("rdv_apikey") + "/availability"
      );
    }
  };
  const handleConfirm = () => {
    let condition = phoneValid && nameValid && emailValid;
    if (
      props.rdvState.company?.rdv_phone_required == 1 &&
      props.rdvState.company?.rdv_email_required == 1
    ) {
      condition = phoneValid && nameValid && emailValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 1 &&
      props.rdvState.company?.rdv_email_required == 0
    ) {
      condition = phoneValid && nameValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 0 &&
      props.rdvState.company?.rdv_email_required == 1
    ) {
      condition = nameValid && emailValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 0 &&
      props.rdvState.company?.rdv_email_required == 0
    ) {
      condition = nameValid;
    }

    if (condition) {
      let phone_num = "";
      let email_add = "";
      if (phone != undefined && phone != "" && phone != null) {
        phone_num = phone.replace("+", "");
      } else {
        phone_num = "null";
      }
      if (email == undefined || email == null) {
        email_add = "";
      } else {
        email_add = email;
      }

      dispatch({ type: "IS_LOADING", payload: true });
      let visitor = {
        country: country,
        /*"phone": '+' + country?.callingCodes[0] + '' + phone,*/
        phone: phone_num,
        name: name,
        email: email_add,
        language: i18next.language,
      };
      dispatch({ type: "SET_VISITOR", payload: visitor });

      if (props.rdvState.operation === "currentqueue") {
        let data = {
          ApiKey: props.rdvState.company?.apikey,
          StoreId: props.rdvState.branch.id,
          ServiceId: props.rdvState.service.id,
          Fullname: name,
          /*"Mobile": country.callingCodes + '' + phone,*/
          Mobile: phone_num,
          Email: email_add,
          language: i18next.language,
          branch: props.rdvState.branch.id,
          service: props.rdvState.service.id,
          CustomerType: props.rdvState.customerTypeChosen
        };
        if (note != undefined && note != "" && note != null) {
          data["Note"] = note;
        }
        props.getTicket(data);
      }
      var agent = "0";
      if (props.rdvState.company?.AllowSelectAgents != 0) {
        agent = props.rdvState.agent?.agent;
      }
      if (props.rdvState.operation === "appointment") {
        let data = {
          fullname: name,
          mobile: phone_num,
          code_country: country.callingCodes[0],
          email: email_add,
          bookdate: props.rdvState.date + " " + props.rdvState.slot + ":00",
          comment: "",
          language: i18next.language,
          branch: props.rdvState.branch.office,
          service: props.rdvState.service.service,
          agent: agent,
          customertype: props.rdvState.customerTypeChosen
        };
        if (note != undefined && note != "" && note != null) {
          data["note"] = note;
        }
        props.getRdv(data);
      } else {
        let data = {};
      }
    } else {
      if (props.rdvState.company?.rdv_phone_required == 1) {
        if (!phoneValid) {
          setPhoneValid(false);
          setPhoneIsInValid(true);
        }
      }
      if (!nameValid) {
        setNameValid(false);
        setNameIsInValid(true);
      }
      if (props.rdvState.company?.rdv_email_required == 1) {
        if (!emailValid) {
          setEmailValid(false);
          setEmailIsInValid(true);
        }
      }
    }
  };

  const handleCancel = () => {
    setShow(true);
  };
  const handleClose = () => setShow(false);

  function getCookie(cName) {
    if (document.cookie.length == 0) return null;

    var regSepCookie = new RegExp("(; )", "g");
    var cookies = document.cookie.split(regSepCookie);

    for (var i = 0; i < cookies.length; i++) {
      var regInfo = new RegExp("=", "g");
      var infos = cookies[i].split(regInfo);
      if (infos[0] == cName) {
        return unescape(infos[1]);
      }
    }
    return null;
  }

  let operationInCookie = getCookie("currentQueueCookie");

  const handleCustomerTypeCheched = (item, e) => {
    dispatch({ type: "SET_CUSTOMER_TYPE", payload: item.Id });
    (props.rdvState.customerTypeChosen !== "undefined" || props.rdvState.customerTypeChosen !== null) ? 
    setCustomerTypeDefined(true) : setCustomerTypeDefined(false);
  }

  const handleYes = () => {
    dispatch({ type: "SET_SERVICE", payload: null });
    dispatch({ type: "SET_SLOT", payload: null });
    dispatch({ type: "SET_DATE", payload: null });
    dispatch({ type: "SET_CUSTOMER_TYPE", payload: null });
    setShow(false);

    if (props.rdvState.source === "appointment") {
      window.location.href =
        "/" +
        props.rdvState.company?.apikey +
        "/" +
        "appointment" +
        "/" +
        sessionStorage.getItem("operationKey") +
        "/" +
        ckie.getSpecificCookie("rdv_lang");
    } else {
      if (props.rdvState.operation === "currentqueue") {
        window.location.href =
          "/" +
          props.rdvState.company?.apikey +
          "/" +
          props.rdvState.operation +
          "/" +
          props.rdvState.branch?.id +
          "/" +
          ckie.getSpecificCookie("rdv_lang");
      } else {
        window.location.href =
          "/" +
          props.rdvState.company?.apikey +
          "/" +
          props.rdvState.operation +
          "/" +
          props.rdvState.branch?.office +
          "/" +
          ckie.getSpecificCookie("rdv_lang");
      }
    }
   
  };
  const goNext = () => {

    if(customerTypeDefined === false && props.rdvState.customersTypes?.is_enable === 1){
       setCustomerTypeError(true);
    } else {
      setCustomerTypeError(false);
    let condition = phoneValid && nameValid && emailValid;
    if (
      props.rdvState.company?.rdv_phone_required == 1 &&
      props.rdvState.company?.rdv_email_required == 1
    ) {
      condition = phoneValid && nameValid && emailValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 1 &&
      props.rdvState.company?.rdv_email_required == 0
    ) {
      condition = phoneValid && nameValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 0 &&
      props.rdvState.company?.rdv_email_required == 1
    ) {
      condition = nameValid && emailValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 0 &&
      props.rdvState.company?.rdv_email_required == 0
    ) {
      condition = nameValid;
    }

    if (condition) {
      let phone_num = "";
      let email_add = "";
      if (phone != undefined && phone != "" && phone != null) {
        phone_num = phone.replace("+", "");
      } else {
        phone_num = "null";
      }
      if (email == undefined || email == null) {
        email_add = "";
      } else {
        email_add = email;
      }

      //dispatch({ type: 'IS_LOADING', payload: true })
      let visitor = {
        country: country,
        /*"phone": '+' + country?.callingCodes[0] + '' + phone,*/
        phone: phone_num,
        name: name,
        email: email_add,
        language: i18next.language,
      };
      dispatch({ type: "SET_VISITOR", payload: visitor });
      dispatch({ type: "NEXT_STEP" });

      // history.push("/" + sessionStorage.getItem("rdv_apikey") + "/summary");
      history.push("/" + ckie.getSpecificCookie("rdv_apikey") + "/summary");
    } else {
      if (props.rdvState.company?.rdv_phone_required == 1) {
        if (!phoneValid) {
          setPhoneValid(false);
          setPhoneIsInValid(true);
        }
      }
      if (!nameValid) {
        setNameValid(false);
        setNameIsInValid(true);
      }
      if (props.rdvState.company?.rdv_email_required == 1) {
        if (!emailValid) {
          setEmailValid(false);
          setEmailIsInValid(true);
        }
      }
    }
  }
  };
  const selectCountry = (c) => {
    setCountry(c);
  };

  useEffect(() => {
    if (props.rdvState.operation == null) {
      dispatch({ type: "IS_LOADING", payload: true });
      history.push("/noroute");
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.rdvState.service !== null) {
    } else goBack();

    window.scrollTo(0, 0);
    return () => { };
  }, []);
  useEffect(() => {
    if (props.rdvState.ticket !== null) {
      history.push("/" + props.rdvState.company?.apikey + "/success");
    } else if (props.rdvState.rdv !== null) {
      history.push("/" + props.rdvState.company?.apikey + "/sent");
    }
  }, [props.rdvState.ticket, props.rdvState.rdv]);

  const handlePhoneInter = (e) => {
    let tphone1 = e;
    if (e?.length > 0) {
      if (isValidPhoneNumber(tphone1)) {
        setPhone(e);
        setPhoneValid(true);
        setPhoneIsInValid(false);
      } else {
        setPhoneErrMsg(t("phone_form_error_invalid"));
        setPhoneValid(false);
        setPhoneIsInValid(true);
      }
    } else {
      setPhone("");
      setPhoneErrMsg(t("phone_form_error"));
      setPhoneValid(null);
      setPhoneIsInValid(null);
    }
  };

  const handleName = (e) => {
    e.target.value = e.target.value.replace(/  +/g, " ").replace(/^\s+/g, "");
    //setName(e.target.value)
    if (e.target.value.length > 0) {
      if (e.target.value.match("^[a-zA-Z\\d]+(?: [a-zA-Z\\d]+)*$") !== null) {
        setName(e.target.value);
        setNameValid(true);
        setNameIsInValid(false);
      } else {
        setNameErrMsg(t("name_form_error_invalid"));
        setNameValid(null);
        setNameIsInValid(null);
      }
    } else {
      setNameErrMsg(t("name_form_error"));
      setNameValid(null);
      setNameIsInValid(null);
    }
  };
  const handleNote = (e) => {
    e.target.value = e.target.value.replace(/  +/g, " ").replace(/^\s+/g, "");
    //setName(e.target.value)
    setNote(e.target.value);
    setNoteValid(true);
    setNoteIsInValid(false);
  };
  const handleEmail = (e) => {
    let temail = e.target.value;
    if (e.target.value.length > 0) {
      if (
        temail
          .toLowerCase()
          .match(
            "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"
          ) !== null
      ) {
        setEmail(e.target.value);
        setEmailValid(true);
        setEmailIsInValid(false);
      } else {
        setEmailErrMsg(t("email_form_error_invalid"));
        setEmailValid(false);
        setEmailIsInValid(true);
      }
    } else {
      setEmail("");
      setEmailErrMsg(t("email_form_error"));
      setEmailValid(null);
      setEmailIsInValid(null);
    }
  };
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  let rdv_phone_required = props.rdvState.company?.rdv_phone_required;
  let rdv_email_required = props.rdvState.company?.rdv_email_required;
  const BackIcon = () => (
    <svg
      className={"orangeSvg_1"}
      style={{ marginRight: 10 }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12.162"
      viewBox="0 0 16 12.162"
    >
      <g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
        <path
          id="Path_891"
          data-name="Path 891"
          d="M5,9.335,0,4.661,5,0"
          fill="none"
          stroke={
            props.rdvState.company?.color_scheme.secondary_color
              ? "#FFF"
              : "#808080"
          }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_262"
          data-name="Line 262"
          x2="13.809"
          transform="translate(0.191 4.774)"
          fill="none"
          stroke={
            props.rdvState.company?.color_scheme.secondary_color
              ? "#FFF"
              : "#808080"
          }
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );

  const [phoneValue, setValue] = useState();

  return (
    <div className={"InfosForm"} id={"InfosForm"}>
      {/* Start of Modal */}
      <Modal style={{ borderRadius: `0` }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.rdvState.operation === "currentqueue" ? (
              <div style={{ textSize: `24px`, opacity: `1` }}>
                {t("about_leave_queue")}
              </div>
            ) : (
              <div
                style={{
                  font: `normal normal bold 24px/26px Helvetica Neue`,
                  textSize: `24px`,
                  opacity: `1`,
                }}
              >
                {t("about_leave_appointment")}
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.rdvState.operation === "currentqueue" ? (
            <div
              style={{ font: `normal normal bold 16px/19px Helvetica Neue` }}
            >
              {t("leave_queue_question")}
            </div>
          ) : (
            <div
              style={{ font: `normal normal bold 16px/19px Helvetica Neue` }}
            >
              {t("cancel_appointment_question")}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={"d-flex justify-content-start"}>
          <button
            style={{
              background: `#FFFFFF`,
              border: `2px solid #000000`,
              opacity: `1`,
              textColor: `#000000`,
              width: `62px`,
              height: `40px`,
            }}
            onClick={handleClose}
          >
            {t("no")}
          </button>
          <button
            style={{
              border: `none`,
              background: `#FF7900`,
              textColor: `#000000`,
              opacity: `1`,
              width: `62px`,
              height: `40px`,
            }}
            onClick={handleYes}
          >
            {t("yes")}
          </button>
        </Modal.Footer>
      </Modal>
      {/* End of Modal */}

      <Row>
        {isMobile ? (
          <div className="col-12">
            {props.rdvState.operation === "currentqueue" ? (
              <h1 style={{ fontWeight: `bold`, marginLeft: `5px`, fontFamily: `Helvetica Neue` }}>
                {t("join_the_current_queue")}
              </h1>
            ) : (
              <h1 style={{ fontWeight: `bold`, marginLeft: `5px`, fontFamily: `Helvetica Neue` }}>
                {t("book_appointment")}
              </h1>
            )}
          </div>
        ) : (
          <div className="col-12">
            {props.rdvState.operation === "currentqueue" ? (
              <h1 style={{ fontWeight: `bold`, marginLeft: `60px`, fontFamily: `Helvetica Neue` }}>
                {t("join_the_current_queue")}
              </h1>
            ) : (
              <h1 style={{ fontWeight: `bold`, marginLeft: `60px`, fontFamily: `Helvetica Neue` }}>
                {t("book_appointment")}
              </h1>
            )}
          </div>
        )}
        <hr
          className="col-sm-12 col-lg-12 col-md-12"
          style={{ marginBottom: `50px`, marginTop: `15px` }}
        ></hr>
      </Row>
      <Row>
        <Col sm={2} lg={2} md={1} xs={1}></Col>
        <Col sm={8} lg={8} md={10} xs={10}>
          <ArrowStepper style={{ marginTop: `40px` }} />
        </Col>
        <Col sm={2} lg={2} md={1} xs={1}></Col>
      </Row>
      <Row>
        <Col md={2} sm={2} lg={2} xs={1}></Col>
        <Col md={8} sm={8} lg={8} xs={10}>
          <div
            className={"mb-3"}
            style={{
              maxWidth: `381px`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
          >
            <div
              style={{
                font: `normal normal bold 16px/19px Helvetica Neue`,
                marginTop: `30px`,
                marginBottom: `40px`,
                color: `#000000D9`,
                opacity: `1`,
                letterSpacing: `-0.13px`,
                height: `24px`,
              }}
            >
              {t("enter_infos")}
            </div>

            <Form.Group controlId="">
              <Form.Label className={"orange-body"}>
                {t("mobile")}
                <span
                  hidden={rdv_phone_required === 0}
                  title={t("required")}
                  style={{ color: "#b30000" }}
                >
                  *
                </span>{" "}
              </Form.Label>
              <PhoneInput
                international
                className={
                  (phoneIsInValid
                    ? "is-invalid phoneInputInvalidBorder"
                    : phoneValid
                      ? "is-valid"
                      : null,
                    "PhoneInput-orange form-control form-control-lg")
                }
                defaultCountry={props.rdvState.company?.Country}
                value={phoneValue}
                placeholder={t("phone_placeholder")}
                onChange={handlePhoneInter}
                error={
                  phoneValue
                    ? isValidPhoneNumber(phoneValue)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
              //      style={{backgroundImage: `url("../../assets/separator.svg")`,
              //              backgroundRepeat: `no-repeat`,
              //     backgroundPosition: `left calc(0.375em + 0.1875rem) center`,
              //     backgroundSize: `calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)`
              // }}
              />
            </Form.Group>

            <div
              className={"invalid-feedback invalid-feedback-me"}
              style={{ display: phoneIsInValid ? "block" : "none" }}
            >
              <img
                src={errorNext}
                style={{ width: `15px`, height: `13px`, marginRight: `8px` }}
              />
              {phoneErrMsg}
            </div>
            <div
              className={
                phoneIsInValid ? "invalid-feedback-me-e" : "invalid-feedback"
              }
              style={{ display: phoneIsInValid ? "block" : "none" }}
            ></div>
            <div
              className={
                phoneValid ? "invalid-feedback-me-s" : "invalid-feedback"
              }
              style={{ display: phoneValid ? "block" : "none" }}
            ></div>

            <Form.Group controlId="">
              <Form.Label className="orange-body">
                {t("fullname")}
                <span style={{ color: "#b30000" }} title={t("required")}>
                  *
                </span>
              </Form.Label>
              <Form.Control
                style={{
                  borderColor: `#CCCCCC`,
                  borderRadius: `0`,
                  height: `40px`,
                  font: `normal normal 16px/19px Helvetica Neue`,
                }}
                type="text"
                placeholder={t("enter_your_name")}
                size={"lg"}
                isValid={nameValid}
                isInvalid={nameIsInValid}
                onChange={handleName}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  font: `normal normal bold 14px/17px Helvetica Neue`,
                  color: `#000000`,
                  opacity: `1`,
                }}
              >
                <img
                  src={errorNext}
                  style={{ width: `15px`, height: `13px`, marginRight: `8px` }}
                />
                {nameErrMsg}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label className={"orange-body"}>
                {t("email")}
                <span
                  hidden={rdv_email_required === 0}
                  title={t("required")}
                  style={{ color: "#b30000" }}
                >
                  *
                </span>
              </Form.Label>
              <Form.Control
                style={{
                  borderColor: `#CCCCCC`,
                  borderRadius: `0`,
                  height: `40`,
                  font: `normal normal 16px/19px Helvetica Neue`,
                }}
                type="email"
                placeholder={t("enter_your_email")}
                size={"lg"}
                isValid={emailValid}
                isInvalid={emailIsInValid}
                onChange={handleEmail}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  font: `normal normal bold 14px/17px Helvetica Neue`,
                  color: `#000000`,
                  opacity: `1`,
                }}
              >
                <img
                  src={errorNext}
                  style={{ width: `15px`, height: `13px`, marginRight: `8px` }}
                />
                {emailErrMsg}
              </Form.Control.Feedback>
            </Form.Group>

            {/* start B2B or B2C for OCI */}
            {props.rdvState.customersTypes?.is_enable === 1 ? 
            <Form.Group controlId="">

            <Form.Label className={"orange-body"}>
                {t("client_type")}
                <span
                  // hidden={rdv_email_required === 0}
                  title={t("required")}
                  style={{ color: "#b30000" }}
                >
                  *
                </span>
              </Form.Label>
              <br/>
                <Row className="ml-0">
                  {props.rdvState.customersTypes?.items?.map((item, i) =>(<div key={i}>
                     <input id={item.Id} type="radio" class="customer-check" name="customer-check" onChange={(e) => handleCustomerTypeCheched(item, e)} />
                     <label htmlFor="customer-check" style={{marginRight: `20px`,font: `normal bold 16px/19px Helvetica Neue`,}}>{item.Name}</label>
                     </div>
                  ))}
                
                  </Row>
                 {(customerTypeError === true && customerTypeDefined === false) ? <Row><span className="customer-type-error">{t("customer_error")}</span></Row> : null}

                  {/* </div> */}
          </Form.Group> : null }
                {/* end B2B or B2C for OCI */}

                {/* 3 Buttons Start */}

                {isMobile ? (
                  <div
                    style={{
                      paddingRight: `20px`,
                      paddingLeft: `20px`,
                      marginTop: `30px`,
                    }}
                  >
                    <Row
                      className={"d-flex justify-content-between flex-nowrap"}
                      style={{ marginBottom: `10px` }}
                    >
                      <button
                        style={{
                          font: `normal normal bold 16px/19px Helvetica Neue`,
                          backgroundColor: `#FFFFFF`,
                          borderColor: `#000000`,
                          opacity: `1`,
                          color: `#000000`,
                          textTransform: `capitalize`,
                          paddingLeft: `20px`,
                          paddingRight: `20px`,
                          height: `40px`,
                        }}
                        onClick={handleCancel}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        style={{
                          font: `normal normal bold 16px/19px Helvetica Neue`,
                          backgroundColor: `#000000`,
                          textTransform: `capitalize`,
                          color: `#FFFFFF`,
                          opacity: `1`,
                          height: `40px`,
                          paddingRight: `20px`,
                          paddingLeft: `20px`,
                          border: `2px solid #000`,
                          borderRadius: `0px`,
                        }}
                        onClick={() => {
                          goBack();
                        }}
                      >
                        {t("back")}
                      </button>
                    </Row>
                    <Row>
                      <button
                        style={{
                          font: `normal normal bold 16px/19px Helvetica Neue`,
                          backgroundColor: `#FF7900`,
                          border: `none`,
                          opacity: `1`,
                          color: `#000000`,
                          textTransform: `capitalize`,
                          width: `100%`,
                          height: `40px`,
                        }}
                        onClick={goNext}
                      >
                        {t("next")}
                      </button>
                    </Row>
                  </div>
                ) : (
                  <Row
                    className="d-flex justify-content-between flex-nowrap"
                    style={{ marginBottom: `20px` }}
                  >
                    <button
                      style={{
                        font: `normal normal bold 16px/19px Helvetica Neue`,
                        marginLeft: `15px`,
                        backgroundColor: `#FFFFFF`,
                        borderColor: `#000000`,
                        opacity: `1`,
                        color: `#000000`,
                        textTransform: `capitalize`,
                        paddingLeft: `20px`,
                        paddingRight: `20px`,
                        height: `40px`,
                      }}
                      onClick={handleCancel}
                    >
                      {t("cancel")}
                    </button>
                    <div>
                      <button
                        style={{
                          font: `normal normal bold 16px/19px Helvetica Neue`,
                          backgroundColor: `#000000`,
                          textTransform: `capitalize`,
                          color: `#FFFFFF`,
                          opacity: `1`,
                          height: `40px`,
                          paddingRight: `20px`,
                          paddingLeft: `20px`,
                          border: `2px solid #000`,
                          marginRight: `12px`,
                          borderRadius: `0px`,
                        }}
                        onClick={() => {
                          goBack();
                        }}
                      >
                        {t("back")}
                      </button>
                      <button
                        style={{
                          font: `normal normal bold 16px/19px Helvetica Neue`,
                          backgroundColor: `#FF7900`,
                          border: `none`,
                          opacity: `1`,
                          color: `#000000`,
                          textTransform: `capitalize`,
                          paddingLeft: `20px`,
                          paddingRight: `20px`,
                          height: `40px`,
                          marginRight: `15px`,
                        }}
                        onClick={goNext}
                      >
                        {t("next")}
                      </button>
                    </div>
                  </Row>
                )}

                {/* 3 Buttons End */}

                {/* ==================Start Modal Permanent================= */}

                <Row className="inform mt-4">
                  {/* Start of info */}
                  <div
                    className="d-flex flex-nowrap mb-3"
                    style={{
                      height: `78px`,
                      border: `2px solid #527EDB`,
                      opacity: `1`,
                      paddingTop: `10px`,
                    }}
                  >
                    <Col sm={1} md={1} lg={1} xs={1}>
                      <FcInfo
                        style={{
                          marginRight: `5px`,
                          width: `25px`,
                          height: `25px`,
                        }}
                      />
                    </Col>

                    <Col
                      sm={11}
                      md={11}
                      lg={11}
                      xs={11}
                      style={{
                        font: `normal bold 14px/15px Helvetica Neue`,
                        letterSpacing: `-0.1px`,
                        color: `#000000`,
                        opacity: `1`,
                      }}
                    >
                      *

                      {props.rdvState.operation === "currentqueue" ? (
                        <>{t("we_use_for_ticket")}</>
                      ) : (
                        <>{t("we_use_for_rdv")}</>
                      )}
                    </Col>
                  </div>

                  {/* End of info */}
                </Row>

                {/* ===================End Modal Permanent=================== */}
              </div>
            </Col>
            <Col md={2} sm={2} lg={2} xs={1}></Col>
          </Row>
        </div>
        );
};

        export default connect(mapStateToProps, mapDispatchToProps)(InfosForm);
