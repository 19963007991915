import React, { useEffect } from 'react';
import './SentScreen.css'
import sentSvg from "../../assets/sent.svg";
import confirmSvg from '../../assets/icons-functional_UI-S-Z-ic_Tick_confirmation.svg';
import { Col, Row } from "react-bootstrap";
import timeSvg from "../../assets/time.svg";
import { useHistory, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as rdvActions from "../../actions";
import { FcInfo } from 'react-icons/fc'
import * as ckie from "../../cookieFunctions"


const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}








const SentScreen = (props) => {

  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;


  let history = useHistory();
  const { t, i18n } = useTranslation();
  const { apiKey } = useParams();

  let dispatch = useDispatch();
  const goHome = () => {

    dispatch({ type: 'SET_SERVICE', payload: null })
    dispatch({ type: 'SET_SLOT', payload: null })

    // if(props.rdvState.operation === "currentqueue"){
    //     window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.id  + '/' + localStorage.getItem('rdv_lang')

    //   }else{
    //     window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.office  + '/' + localStorage.getItem('rdv_lang')
    //   }
    // if(sessionStorage.getItem('operationKey')) {
    if (ckie.getSpecificCookie('operationKey')) {
      console.log("Cookie")
      console.log(ckie.getSpecificCookie('operationKey'))
      // window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + sessionStorage.getItem('operationKey')  + '/' + ckie.getSpecificCookie('rdv_lang')
      window.location.href = '/' + props.rdvState.company?.apikey + '/' + "appointment" + '/' + ckie.getSpecificCookie('operationKey') + '/' + ckie.getSpecificCookie('rdv_lang')
    } else {
      var office_id = "";
      if (props.rdvState.branch?.office) {
        office_id = props.rdvState.branch?.office;
      }
      else {
        office_id = props.rdvState.branch?.id;
      }
      window.location.href = '/' + props.rdvState.company?.apikey + '/' + props.rdvState.operation + '/' + office_id + '/' + ckie.getSpecificCookie('rdv_lang')
    }


  }
  const goBack = () => {
    // history.push('/' + sessionStorage.getItem('rdv_apikey') + '/informations')
    history.push('/' + ckie.getSpecificCookie('rdv_apikey') + '/informations')
  }


  return <div className={'SentScreen'} id={'SentScreen'}>
    <div className={'text-center'} style={{ margingBottom: `35px`, marginTop: `85px` }}><img src={confirmSvg} alt={''} /></div>

    {props.rdvState.operation === "currentqueue" ? (
      <> <div className={'text-center'} style={{ font: `bold`, marginBottom: `10px`, marginTop: `30px` }}>{t('in_queue')}</div>
        <div className={'text-center'} style={{ font: `bold`, marginBottom: `40px` }}>{t('now_queue')}  <span style={{ fontWeight: `bold` }}>{props.rdvState.ticket?.ticket}</span></div> </>
    ) : (
      <><div className={'text-center'} style={{ font: `bold`, marginBottom: `10px`, marginTop: `30px` }}>{t('appointment_confirmed')}</div>
        <div className={'text-center'} style={{ font: `bold`, marginBottom: `40px` }}>{t('enter_reservation_code')} <span style={{ fontWeight: `bold` }}> {props.rdvState.rdv.numrdv} </span> {t('your_arrival')}</div></>
    )}


    <Row style={{ marginTop: `40px` }}>
      <Col md={4} sm={4} lg={4} xs={1}></Col>

      <Col md={4} sm={4} lg={4} xs={10}>
        <div style={{ maxWidth: `490px`, marginLeft: `auto`, marginRight: `auto` }}>
          <Row style={{ color: "#000000", opacity: "1", fontWeight: "bold", letterSpacing: "-0.2px" }}>{t('name')}</Row>
          <Row style={{ marginBottom: "7px" }}>{props.rdvState.visitor?.name}</Row>
          <Row style={{ color: "#000000", opacity: "1", fontWeight: "bold", letterSpacing: "-0.2px" }}>{t('phone')}</Row>
          <Row style={{ marginBottom: "7px" }}>{props.rdvState.visitor?.phone}</Row>
          <Row style={{ color: "#000000", opacity: "1", fontWeight: "bold", letterSpacing: "-0.2px" }}>{t('mail')}</Row>
          <Row style={{ marginBottom: "7px" }}>{props.rdvState.visitor?.email}</Row>

          <Row style={{ color: "#000000", opacity: "1", fontWeight: "bold", letterSpacing: "-0.2px" }}>{t('branch')}</Row>
          <Row>{props.rdvState.branch?.Name}
            {props.rdvState.branch?.name}
          </Row>
          <Row style={{ marginBottom: "7px" }}>
            {props.rdvState.branch?.address}
            {props.rdvState.branch?.Address}</Row>
          <Row style={{ color: "#000000", opacity: "1", fontWeight: "bold", letterSpacing: "-0.2px" }}>{props.rdvState.operation === "appointment" ? t('appointment_reason') : t('visit_reason')}</Row>
          <Row style={{ marginBottom: "7px" }}>{props.rdvState.service?.translate ? props.rdvState.service?.translate[i18next.language] : props.rdvState.service?.name ? props.rdvState.service?.name[i18next.language] : ''}</Row>
          {props.rdvState.operation === "appointment" ? <>
            <Row style={{ color: "#000000", opacity: "1", fontWeight: "bold", letterSpacing: "-0.2px" }}>{t('date_time')}</Row>
            <Row className={'d-flex flex-nowrap'}>{props.rdvState.date ? moment(props.rdvState.date, 'YYYY-MM-DD').locale(i18n.language).format("dddd Do, MMMM YYYY") : moment().format("dddd Do, MMMM YYYY")} {" "} {t('at')} {" "} {props.rdvState.slot}</Row>
          </> : null}

        </div>
      </Col>

      <Col md={4} sm={4} lg={4} xs={1}></Col>
    </Row>
    {/* Go Back Text Remove */}
    {/* <div className={'text-center'} style={{ marginTop: `40px` }}> */}
    {/* <button style={{width: `92px`, height: `41px`, backgroundColor: `#FFFFFF`, color: `#000000`, border: `2px solid #000000` }}  onClick={goHome}>{t('return_home')}</button> */}
    {/* {t("go_back_text")} */}
    {/* </div> */}
    {props.rdvState.operation === "appointment" &&
      <Row className="inform mt-4" style={{ maxWidth: `500px`, marginLeft: `auto`, marginRight: `auto` }}>
        {/* Start of info */}
        <div className="d-flex flex-nowrap mb-3" style={{ border: `2px solid #527EDB`, opacity: `1`, padding: `15px` }}>
          <Col sm={1} md={1} lg={1} xs={1}>
            <FcInfo style={{ marginRight: `5px`, width: `25px`, height: `25px` }} />
          </Col>

          <Col className={'d-flex flex-wrap'} sm={11} md={11} lg={11} xs={11} style={{ font: `normal bold 16px/19px Helvetica Neue`, letterSpacing: `-0.1px`, color: `#000000`, opacity: `1` }}>

            {t('msg_to_respect_rdv')}
          </Col>
        </div>

        {/* End of info */}
      </Row>

    }

  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(SentScreen);
