import React, { useEffect, useState } from 'react';
import './BranchService.css';
import clockSvg from '../../assets/clock.svg';
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { GoCheck } from "react-icons/go";
import { Row, Col } from "react-bootstrap";
import { FaServicestack } from "react-icons/fa"
import PathSvg from '../../assets/Path.svg';
import Service3Svg from '../../assets/service3.svg';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const BranchService = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  let dispatch = useDispatch();
  let serviceName = props.service?.translate ? props.service?.translate[i18next.language] : props.service?.name ? props.service?.name[i18next.language] : ''
  const handleSelectService = () => {
    dispatch({ type: 'SET_SERVICE', payload: props.service })
    // if (props.rdvState.operation === "appointment") {
    //   if (props.rdvState.company?.AllowSelectAgents != 0){
    //     history.push('/' + props.rdvState.company?.apikey + '/agents')
    //   }else{
    //     history.push('/' + props.rdvState.company?.apikey + '/availability')
    //   }
    // } else history.push('/' + props.rdvState.company?.apikey + '/informations')

  }
  //let serviceNameNormalized = serviceName[0].toUpperCase() + serviceName.substr(1).toLowerCase()
  

  return <div onClick={handleSelectService} id={'BranchService'} 
  
  > 


    <div 
      className={props.rdvState.operation === "appointment" ? 'd-flex  mb-3 appointmentService mx-auto' : 'd-flex  mb-3 queueService'}
    style={props.rdvState.service !== null && props.rdvState.service.name == props.service.name ? { border: `2px solid #000000` } : {
      border: `1px solid #CCCCCC`
    }}>
				<div className="d-flex"><img className="my-auto" src={`https://manage.right-q.com/themes/rightq/assets/admin/layout/img/icons_services/` + props.service.icon}
						style={{width: `25px`, height: `35px`, marginLeft: `30px`}} /></div>
            {/* <div className="d-flex" style={{marginTop: `auto`, marginBottom: `auto`, border: `solid 1px black`, width: `25px`, height: `35px`, marginLeft: `30px`}}><img className="my-auto" src={`https://demos.right-q.com/themes/rightq/assets/admin/layout/img/icons_services/` + props.service.icon}
						style={{width: `100%`}} /></div> */}
				<div className="d-flex">
					<div className="d-flex flex-column my-auto">
						<div className="row"
							style={{marginLeft: `10px`, marginRight: `5px`, font: `normal normal bold 16px/18px Helvetica Neue`, opacity: `1`, color: `#000000`}}>
							{/* {serviceNameNormalized} */}
              {serviceName}
              </div>
						<div className="row" hidden={props.rdvState.operation === 'appointment'}
							style={{marginLeft: `10px`, font: `normal normal normal 16px/18px Helvetica Neue`, opacity: `1`, color: `#000000`}}>
							{props.service.WaitingPeople == undefined ? "0" : props.service.WaitingPeople} {t('waiting_people')}</div>
						<div className="row" hidden={props.rdvState.operation === 'appointment'}
							style={{marginLeft: `10px`, font: `normal normal normal 16px/18px Helvetica Neue`, opacity: `1`, color: `#000000`}}>
							 <img src={clockSvg}  alt={""} style={{ width: `17px`, height: `17px`, marginRight: `8px`, marginTop: `2px` }} /> {props.service.WaitingTime == undefined ? "0" : props.service.WaitingTime} {t('min')}
              </div>


					</div>
				</div>
				<div className="d-flex ml-auto">{props.rdvState.service !== null && props.rdvState.service.name == props.service.name ? <img src={PathSvg} className={'my-auto'} style={{ height: `26px`, width: `30px`, marginRight: `20px`, color: `#32C832` }} /> : null}</div>
			</div>


  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchService);
