import React, { useState, useEffect } from 'react'
import './ClientTypeSelection.css'
import {useTranslation} from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux'
import { useHistory, useParams } from "react-router-dom";
import * as rdvActions from "../../actions";
import ArrowStepper from '../ArrowStepper/ArrowStepper';
import { useMediaQuery } from 'react-responsive'


const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: e => dispatch(rdvActions.getServices(e),),
        
        fromAppointmentToCurrentQueue: (a, o) => dispatch(rdvActions.fromAppointmentToCurrentQueue(a, o)),
    }
}

const ClientTypeSelection = (props) => {
    const [clientType, setClientType] = useState();
    const { t } = useTranslation();
  let history = useHistory();
  let dispatch = useDispatch();
    const onParticularClientTypeClick = () => {
            // alert('Client Particulier Selected')
            dispatch({ type: 'NEXT_STEP' });
               
                history.push('/' + props.rdvState.company?.apikey + '/service')

    }
    const onProfessionalClientTypeClick = () => {
             alert('Client Pro Selected')
             
    }

    useEffect(() => {
            // console.log(props.rdvState)
    }, [])

    return (
        <div className={'ClientTypeSelection'}>
             <div className="col" style={{ width: `810px`, marginRight: `auto`, marginLeft: `auto` }}>

            <ArrowStepper style={{ marginBottom: `30px`}}/>

            <Row className={'RowContainer'}>
                <Col md={2} lg={2} sm={2} xs={2}></Col>
                <Col md={4} lg={4} sm={4} xs={4}>
                    <Card className={'CardStyle text-center'} onClick={onParticularClientTypeClick}>{t('client_pro')}</Card>
                </Col>
                <Col md={4} lg={4} sm={4} xs={4}>
                <Card className={'CardStyle text-center'} onClick={onProfessionalClientTypeClick}>{t('client_perso')}</Card>
                </Col>
                <Col md={2} lg={2} sm={2} xs={2}></Col>
            </Row>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientTypeSelection)
