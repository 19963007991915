const initialState = {
    articles: [],
    remoteArticles: [],
    source: null,
    isLoading: false,
    company: null,
    apiKey: null,
    operation: null,
    branch: null,
    branches: [],
    service: null,
    serviceClosed: null,
    services: [],
    visitor: null,
    noAgent: true,
    agent: null,
    agents: [],
    availabilities: [],
    date: null,
    slot: null,
    ticket: null,
    rdv: null,
    rdvData: null,
    currentStepIndice: 0,
    operationKey: null,
    confirmBtnActive: true,
    cancelRDVStatus: null,
    showClientType: false, //will change this after
    clientType: null,
    customersTypes: null,
    customerTypeChosen: null
};

function rootReducer(state = initialState, action) {
    if (!state) {
        return null;
    }
    //console.log(action);
    switch (action.type) {
        case "DO_SOMETHING": {
            //console.log("state : ", { ...state });
            // console.log(state);
            // console.log(action);
            return {...state, something: action.payload};
        }
        case "ACTIVE_SUMMARY_BTN": {
            //console.log("state : ", { ...state });
            // console.log(state);
            // console.log(action);
            return {...state, confirmBtnActive: action.payload};
        }
        case "ADD_ARTICLE": {
            return Object.assign({}, state, {
                articles: state.articles.concat(action.payload)
            });
        }
        case "DATA_LOADED": {
            return Object.assign({}, state, {
                remoteArticles: state.remoteArticles.concat(action.payload)
            });
        }
        case "SET_OPERATION": {
            return {...state, operation: action.payload};
        }
        case "IS_LOADING": {
            return {...state, isLoading: action.payload};
        }
        case "SET_BRANCH": {
            return {...state, branch: action.payload};
        }
        case "SET_BRANCHES": {
            return {...state, branches: action.payload};
        }
        case "SET_SERVICE": {
            return {...state, service: action.payload};
        }
        case "SET_SERVICES_CLOSED": {
            return {...state, serviceClosed: action.payload};
        }
        case "SET_SERVICES": {
            return {...state, services: action.payload};
        }
        case "SET_VISITOR": {
            // console.log(action.payload);
            return {...state, visitor: action.payload};
        }
        case "SET_AGENT": {
            return {...state, agent: action.payload};
        }
        case "SET_URL_SOURCE": {
            return {...state, source: action.payload};
        }
        case "SET_NO_AGENTS": {
            return {...state, noAgent: action.payload};
        }
        case "SET_AGENTS": {
            return {...state, agents: action.payload};
        }
        case "SET_SLOT": {
            return {...state, slot: action.payload};
        }
        case "SET_AVAILABILITIES": {
            return {...state, availabilities: action.payload};
        }
        case "SET_CUSTOMERS_TYPES": {
            return {...state, customersTypes: action.payload};
        }
        case "SET_CUSTOMER_TYPE": {
            return {...state, customerTypeChosen: action.payload};
        }
        case "SET_DATE": {
            return {...state, date: action.payload};
        }
        case "GET_TICKET": {
            return {...state, ticket: action.payload};
        }
        case "GET_RDV": {
            return {...state, rdv: action.payload};
        }
        case "SET_COMPANY": {
            return {...state, company: action.payload};
        }
        case "SET_NO_BACK_BUTTON":{
            return {...state, back_button: 1};
        }
        case "APIKEY": {
            return {...state, apiKey: action.payload};
        }
        case "GET_RDV_DATA": {
            return {...state, rdvData: action.payload};
        }
        case "NEXT_STEP": {
            // console.log("Stepper")
            return {...state, currentStepIndice: state.currentStepIndice + 1};
        }
        case "PREVIOUS_STEP": {
            return state.currentStepIndice > 0 ? {
                ...state, currentStepIndice: state.currentStepIndice - 1
            }:{...state, currentStepIndice: state.currentStepIndice};
        }
        case "CURRENTQUEUE_OP": {
            return {...state, operationKey: state.operationKey + 1  };
        }
        case "CANCEL_RDV_STATUS" :
            return {...state, cancelRDVStatus: action.payload}
            case "CLIENT_TYPE" : 
            return {...state, clientType: action.payload};
            case "SHOW_CLIENT_TYPE" : 
            return {...state, showClientType: action.payload};
        default:
            return state;
        }

}

export default rootReducer;

