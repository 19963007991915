const constants = {
    "DEFAULT_ORANGE_URL" : "https://orange.com",
    "COMPANY_URLS" : {
        "amdGdnJsTnRUbVh5aERlWUFLWFFnQT09" : "https://agence.orange.sn"
    },
    "COMPANY_HEADER_URLS" : {
        // Orange Sonatel
        "amdGdnJsTnRUbVh5aERlWUFLWFFnQT09" : {
            "particulier" : "https://www.orange.sn",
            "professional" : "https://www.orangebusiness.sn",
            "enterprise" : "https://www.orangebusiness.sn",
            "stores" : "https://agence.orange.sn",
            "assistance" : "https://assistance.orange.sn",
            "mobile" : "https://www.orange.sn/offres-mobiles",
            "internet" : "https://www.orange.sn/internet-adsl-fibre/new-fibre",
            "money" : "https://www.orange.sn/orange-money"
        },
        // RightCom
        "aGxyVlNLY0xOLzN0MGxzcHpheEFBdz09" : {
            "particulier" : "https://www.orange.ci",
            "professional" : "https://business.orange.ci",
            "enterprise" : "https://business.orange.ci",
            "stores" : "https://agence.orange.ci",
            "assistance" : "https://www.orange.ci/fr/assistance.html",
            "mobile" : "https://www.orange.ci/fr/profils-et-avantages.html",
            "internet" : "https://www.orange.ci/fr/internet-adsl-fibre-et-ligne-fixe-orange.html",
            "money" : "https://www.orange.ci/fr/n-orange-money.html"
        },
        // Orange CI
        "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" : {
            "particulier" : "https://www.orange.ci",
            "professional" : "https://business.orange.ci",
            "enterprise" : "https://business.orange.ci",
            "stores" : "https://agence.orange.ci",
            "assistance" : "https://www.orange.ci/fr/assistance.html",
            "mobile" : "https://www.orange.ci/fr/profils-et-avantages.html",
            "internet" : "https://www.orange.ci/fr/internet-adsl-fibre-et-ligne-fixe-orange.html",
            "money" : "https://www.orange.ci/fr/n-orange-money.html"
        },
        // Orange Guinee
        "cEdzQzh0bnJyVGljMHdzZUFLQmRIdz09" : {
            "particulier" : "https://www.orange-guinee.com",
            "professional" : "https://www.orange-guinee.com/business",
            "enterprise" : "https://www.orange-guinee.com/business",
            "stores" : "https://agence.orange-guinee.com",
            "assistance" : "https://www.orange-guinee.com/fr/assistance.html",
            "mobile" : "https://www.orange-guinee.com/fr/mobile.html",
            "internet" : "https://www.orange-guinee.com/fr/internet-et-tv-new.html",
            "money" : "https://www.orange-guinee.com/fr/orange-money.html"
        }
    }
};
export default constants;

// export function getCookieFromConstants(name) {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(';').shift();
//   }