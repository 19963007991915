import React, { useEffect } from 'react';
import './MainSection.css'
import bg from '../../assets/background.jpg'
import { Route, Router, Switch } from "react-router-dom";
import StartScreen from "../StartScreen";
import RedirectService from "../RedirectService";
import { Container } from "react-bootstrap";
import OperationSelection from "../../stepsComponents/OperationSelection";
import ServiceSelection from "../../stepsComponents/ServiceSelection";
import InfosForm from "../../stepsComponents/InfosForm";
import ConfirmationScreen from "../../stepsComponents/ConfirmationScreen";
import SentScreen from "../../stepsComponents/SentScreen";
import AgentsScreen from "../../stepsComponents/AgentsScreen";
import Availability from "../../stepsComponents/Availability";
import RdvData from "../../stepsComponents/RdvData";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import SummaryScreen from '../../stepsComponents/SummaryScreen/SummaryScreen';
import BranchScreen from '../../stepsComponents/BranchScreen/BranchScreen';
import ChartData from '../../components/ChartData';
import CancelScreen from '../../stepsComponents/CancelScreen/CancelScreen'
import ClientTypeSelection from '../../stepsComponents/ClientTypeSelection/ClientTypeSelection';


const history = createBrowserHistory();
const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
const MainSection = (props) => {
    let setBg = bg;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        let pathName = window.location.pathname.split('/')[2];
        if (!['', 'operation'].includes(pathName)) {
            setBg = null;
        } else setBg = bg
    })

    let setBGOrange = null;
    useEffect(() => {
    }, [props.rdvState.company?.color_scheme.isOrange])

    
    // useEffect(() => {
    //     console.log("currentStepIndice", props.rdvState.currentStepIndice);
    // }, [props.rdvState.currentStepIndice])


    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;

    var currentLocation = window.location.pathname;
    var parts = currentLocation.split('/');
    var lastSegment = parts.pop() || parts.pop();

    var classe_names = 'MainSection deleteMarginTop ';

    /*if(val_isOrange == 1){
      var classe_names = 'MainSection deleteMarginTop ';
    }*/

    if (val_isOrange == 1 && lastSegment == 'operation') {
        /*classe_names = classe_names + 'brGrey';*/
    }
    return <div className={val_isOrange == 1 ? classe_names : 'MainSection'} id={'MainSection'}

        style={val_isOrange === 1 ? (window.location.href.indexOf("/graph/") !== -1) ? { backgroundColor: '#FFF', paddingBottom: '0px' } : { backgroundColor: '#FFF' } : (window.location.href.indexOf("/graph/") !== -1) ? {
            backgroundColor: '#F4F5F6',
            backgroundImage: 'url(' + setBg + ')', paddingBottom: '0px', overflowY: 'hidden'
        } : {
                backgroundColor: '#F4F5F6',
                backgroundImage: 'url(' + setBg + ')'
            }}
    >

        <Container className={val_isOrange == 1 ? 'MainSection-orange' : null}>
            <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
                <Switch>
                    <Route exact path="/backdoor">
                        backdoor
                    </Route>


                    <Route exact path="/:apiKey/cancel/:rdvNum">
                        <CancelScreen />
                    </Route>
                    <Route exact path="/:apiKey/:operation/:officeId/:lang">
                        <RedirectService />
                    </Route>
                    <Route exact path="/:apiKey/rdv/:id">
                        <RdvData />
                    </Route>
                    <Route exact path="/:apiKey/availability">
                        <Availability />
                    </Route>
                    <Route exact path="/:apiKey/informations">
                        <InfosForm />
                    </Route>
                    <Route exact path="/:apiKey/agents">
                        <AgentsScreen />
                    </Route>
                    <Route exact path="/:apiKey/success">
                        <ConfirmationScreen />
                    </Route>
                    <Route exact path="/:apiKey/sent">
                        <SentScreen />
                    </Route>
                    <Route exact path="/client_type">
                        <ClientTypeSelection />
                    </Route>
                    <Route exact path="/:apiKey/service">
                        <ServiceSelection />
                    </Route>
                    <Route exact path="/:apiKey/summary">
                        <SummaryScreen />
                    </Route>
                    <Route exact path="/:apiKey/branch">
                        <BranchScreen />
                    </Route>
                    {/* <Route exact path="/:apiKey/operation">
                        <OperationSelection/>
                    </Route> */}

                    <Route exact path="/:apiKey/graph">
                        <ChartData />
                    </Route>

                    {/* <Route exact path="/:apiKey/">
                        <StartScreen/>
                    </Route> */}

                    <Route exact path="/noroute">
                        <div className={'noroute'}>
                            <h1>404</h1>
                        </div>
                    </Route>
                    <Route exact path="*">
                        <div className={'noroute'}>
                            <h1>404</h1>
                        </div>
                    </Route>
                </Switch>
            </Router>
        </Container>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(MainSection);
