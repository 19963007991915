import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";

import * as ckie from "../src/cookieFunctions"

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

// if (!localStorage.getItem("rdv_lang")) {
if (!ckie.getSpecificCookie("rdv_lang")) {
    let l = navigator.language;
    if (l.includes("en")) {
        // localStorage.setItem("rdv_lang", "en");
        ckie.setSpecificCookie("rdv_lang", "en")
        // console.log("Valeur de ckie", ckie.getSpecificCookie("Test")) 
        
    } else if (l.includes("fr")) {
        // localStorage.setItem("rdv_lang", "fr");
        ckie.setSpecificCookie("rdv_lang", "fr")

        
    }
}
// const definedLan = localStorage.getItem("rdv_lang");
const definedLan = ckie.getSpecificCookie("rdv_lang");


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: definedLan,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            wait: true
        },
        fallbackLng: "en"
    });

export default i18n;