import React, {useState, useEffect} from "react";
import Chart from "react-apexcharts";
import {Col, Row} from "react-bootstrap";
import * as rdvActions from "../../actions";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './ChartData.css'
import moment from 'moment';

const mapDispatchToProps = (dispatch) => {
    return {
        getWaitingTime: (a, o) => dispatch(rdvActions.getWaitingTime(a, o))
    }
};

const NoData = ({t}) => {
    return (
        <div style={{width: "100%", height: 175, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <span>{t("no_data")}</span>
        </div>
    )
}; 

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
};

const ChartData = props => {
    const {t, i18n} = useTranslation();
    const {apiKey, officeId, lang} = useParams();
    const [datas, setDatas] = useState(null);
    const [isEmpty, setIsEmpty] = useState(true);
    let now = moment().format("dddd");

    const [day, setDay] = useState(now);

    useEffect(() => {
        if (typeof apiKey !== "undefined" && typeof officeId !== "undefined") {
            props.getWaitingTime(apiKey, officeId, lang).then(response => {
                response === "" ? setDatas(null) : setDatas(response);
            });
        }
    }, [apiKey, officeId]);

    let labels = [];
    const [state, setState] = useState({
        options: {},
        series: []
    });

    useEffect(() => {
        if (datas !== null) {
            setIsEmpty(true);
            let series = [];
            Object.keys(datas).forEach(function (key1) {
                if (day === key1) {
                    Object.keys(datas[key1]).forEach(function (key2) {
                        if (datas[day][key2] > 0)
                            setIsEmpty(false);
                        let l = key2 + "h";
                        labels.push(l);
                        series.push(datas[day][key2]);
                    });
                }
            });

            setState({
                options: {
                    chart: {
                        id: 'apexchart-example',
                        width: '100%',
                        background: '#fff',
                        fontFamily: "helvetica-55-roman-1",
                        toolbar: {
                            show: false
                        }
                    },
                    grid: {
                        show: true,
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0

                        },
                        column: {
                            width: "100px",
                        },
                    },
                    xaxis: {
                        categories: labels
                    },
                    yaxis: {
                        show: true,
                        floating: false,
                        padding: {
                            right: 1
                        },
                        labels: {
                            show: true,
                            formatter: (value) => { return parseInt(value) },
                            // padding: {
                            //     right: 1
                            // },
                            style: {
                                // cssClass: 'apexcharts-yaxis-label'
                                // marginRight: '20px'
                            }
                        },

                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: true
                    },
                    colors: ["#4BB4E6"]
                },
                series: [{
                    name: t("average_waiting_time") + " (min)",
                    data: series
                }]
            })
        }
    }, [day, datas]);

    const selectDay = (day) => {
        setDay(day);
    };

    const hover = (dayNameComplete, dayIdHover) => {

        document.getElementById(dayIdHover).innerText = dayNameComplete;
    };

    const leave = (dayNameShort, dayIdLeave) => {

        document.getElementById(dayIdLeave).innerText = dayNameShort;
    };


    return (
        <div>
            {/* <span style={{fontWeight: "bold", marginLeft: "35px"}}>{t("periode_aff")} </span> */}
            <Row style={{marginTop: "20px", padding: "10px 10px 10px 50px"}}>
                <Col onClick={() => selectDay("Monday")}
                     id={'Monday'}
                     onMouseOver={() => hover(t('monday_name'), "Monday")}
                     onMouseLeave={() => leave(t('monday_shortname'), "Monday")}
                     className={day === "Monday" ? 'col-style day-selected ' : 'col-style'}>{t('monday_shortname')}</Col>
                <Col onClick={() => selectDay("Tuesday")}
                     id={'Tuesday'}
                     onMouseOver={() => hover(t('tuesday_name'), "Tuesday")}
                     onMouseLeave={() => leave(t('tuesday_shortname'), "Tuesday")}
                     className={day === "Tuesday" ? 'col-style day-selected  ' : 'col-style'}>{t('tuesday_shortname')}</Col>
                <Col onClick={() => selectDay("Wednesday")}
                     id={'Wednesday'}
                     onMouseOver={() => hover(t('wednesday_name'), "Wednesday")}
                     onMouseLeave={() => leave(t('wednesday_shortname'), "Wednesday")}
                     className={day === "Wednesday" ? 'col-style day-selected' : 'col-style'}>{t('wednesday_shortname')}</Col>
                <Col onClick={() => selectDay("Thursday")}
                     id={'Thursday'}
                     onMouseOver={() => hover(t('thursday_name'), "Thursday")}
                     onMouseLeave={() => leave(t('thursday_shortname'), "Thursday")}
                     className={day === "Thursday" ? 'col-style day-selected' : 'col-style'}>{t('thursday_shortname')}</Col>
                <Col onClick={() => selectDay("Friday")}
                     id={'Friday'}
                     onMouseOver={() => hover(t('friday_name'), "Friday")}
                     onMouseLeave={() => leave(t('friday_shortname'), "Friday")}
                     className={day === "Friday" ? 'col-style day-selected' : 'col-style'}>{t('friday_shortname')}</Col>
                <Col onClick={() => selectDay("Saturday")}
                     id={'Saturday'}
                     onMouseOver={() => hover(t('saturday_name'), "Saturday")}
                     onMouseLeave={() => leave(t('saturday_shortname'), "Saturday")}
                     className={day === "Saturday" ? 'col-style day-selected' : 'col-style'}>{t('saturday_shortname')}</Col>
                <Col style={{borderRight: "1px solid black"}} onClick={() => selectDay("Sunday")}
                     id={'Sunday'}
                     onMouseOver={() => hover(t('sunday_name'), "Sunday")}
                     onMouseLeave={() => leave(t('sunday_shortname'), "Sunday")}
                     className={day === "Sunday" ? 'col-style day-selected' : 'col-style'}>{t('sunday_shortname')}</Col>
            </Row>

            {isEmpty ? (<NoData t={t}/>) : (
                <>
                    <Row>
                        <Col style={{marginLeft: "8px", fontSize: "14px", fontWeight: "bold"}}>Min</Col>
                    </Row>
                    <Chart options={state.options} series={state.series} type="bar" height={175}/>
                </>
            )}
        </div>
    )
};
export default connect(mapStateToProps, mapDispatchToProps)(ChartData);
