import React, {useEffect, useState} from 'react';
import './StartScreenCard.css'
import {Link, useHistory} from "react-router-dom";
import {connect, useDispatch} from 'react-redux'
import * as ckie from "../../cookieFunctions"

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const StartScreenCard = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const NextIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} style={{marginLeft: 10}} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162"
                               viewBox="0 0 16 12.162">
    <g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
      <path id="Path_891" data-name="Path 891" d="M5,0,0,4.674,5,9.335" transform="translate(14 9.335) rotate(180)"
            fill="none" stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#1689E1'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(13.809 4.774) rotate(180)" fill="none"
            stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#1689E1'} strokeLinecap="round" strokeWidth="2"/>
    </g>
  </svg>)





  const handleClick = () => {
    dispatch({type: 'SET_OPERATION', payload: props.to});
      // localStorage.setItem('rdv_prev_op', props.to)
      ckie.setSpecificCookie("rdv_prev_op", props.to)
    history.push('/' + props.rdvState.company?.apikey + '/operation')
  }



  const [borderRadius, setBorderRadius] = useState();
  useEffect(() => {
    setBorderRadius(false);
    if(props.rdvState.company !== null && props.rdvState.company?.color_scheme.isOrange === 1){
      setBorderRadius(true);
    }
    
    }, [props.rdvState.company])

  
/*disableCadre*/
    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
    let disableCadre = props.to == "currentqueue" && props.rdvState.company?.apikey == "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09";
    let linkClass = val_isOrange === 1 ? 'link link_left link_hover' : 'link';
    linkClass = linkClass + (disableCadre ? " disable-link":"");
    let StartScreenCardClass = val_isOrange === 1 ? 'StartScreenCardOrange' : 'StartScreenCard';
    StartScreenCardClass = StartScreenCardClass + (disableCadre ? " disable-link-cadre":"");

    let linkContentClass = val_isOrange === 1 ? 'orange-header link_content link_content_white ' : '';
    linkContentClass = linkContentClass + (disableCadre ? " link_content_disable":"");


    let disabletext = val_isOrange === 1 ? 'text text_orange' : 'text';
    disabletext = disabletext + (disableCadre ? " link_content_disable":"");
    disabletext = disableCadre ? disabletext.replace("text_orange","") :disabletext;
/*end disableCadre*/


  return <div className={StartScreenCardClass} id={'StartScreenCard'} style={{borderRadius: borderRadius == true ? 0 : '', height: val_isOrange === 1 ? '400 !important' : '', backgroundColor: props.rdvState.company?.color_scheme.primary_color}}>
    <div>
      <div>
        <img src={props.img} alt={''}/>
      </div>
      <div style={{margin: '20px auto 45px auto'}}>
        <span className={disabletext} style={{color: disableCadre ? '#666666' : props.rdvState.company?.color_scheme.secondary_color}}>{props.text}</span>
      </div>
      <div className={val_isOrange === 1 ? 'mobile_center' : ""} style={{position: "absolute", bottom: 45, width: '100%', left: 0, textAlign: 'center'}}>
        <Link to={'#'} className={linkClass} onClick={handleClick}  style={{borderRadius: borderRadius == true ? 0 : ''}}> 
          <div className={linkContentClass} style={{color: props.rdvState.company?.color_scheme.secondary_color, fontSize: val_isOrange === 1 ? '11px' : ''}}>{props.btnText} <NextIcon/></div>
        </Link>
      </div>
    </div>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(StartScreenCard);
