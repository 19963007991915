import React, {useEffect, useState} from 'react';
import TopNavSection from "../TopNavSection";
import FooterSection from "../FooterSection";
import './AppShell.css'
import MainSection from "../MainSection";
import * as rdvActions from "../../actions";
import {connect, useDispatch} from "react-redux";
import CompanyLoading from "../CompanyLoading";

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCompany: e => dispatch(rdvActions.getCompany(e))
    }
};
const AppShell = (props) => {
    let dispatch = useDispatch();
    let link = window.location.href;
    let index = link.indexOf("graph");
    useEffect(() => {
        const route = window.location.pathname.split('/');
        if (route) {
            //console.log(route[1])
        }
        dispatch({type: 'IS_LOADING', payload: true});
        props.getCompany(route[1])
    }, [])
    useEffect(() => {
        let link = document.querySelector('link[rel="shortcut icon"]') ||
            document.querySelector('link[rel="icon"]');

        if (!link) {
            link = document.createElement('link');
            link.id = 'favicon';
            link.rel = 'shortcut icon';
            document.head.appendChild(link);
        }

        link.href = props.rdvState.company ? props.rdvState.company.companylogo : `/favicon.png`;
    })


    const [showHeader, setShowHeader] = useState();


    //let showHeader = 0;
    useEffect(() => {
        let condition_to_show_header = (props.rdvState.company !== null && props.rdvState.company?.color_scheme.isOrange !== 1) || props.rdvState.company?.apikey == "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09";
        //let condition_to_show_header = props.rdvState.company !== null &&  props.rdvState.company?.apikey !== "aGxyVlNLY0xOLzN0MGxzcHpheEFBdz09" && props.rdvState.company?.apikey !== "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" || index >= 0;
        //let condition_to_show_header = false;
        setShowHeader(0);
        if (condition_to_show_header && index === -1) {
            setShowHeader(1);
        }

    }, [props.rdvState.company]);


    return (
        <div>
            <div className={'AppShell'} id={'AppShell'}>
              {/*<div hidden={showHeader === 0}>
                    <TopNavSection/>
                </div>*/}


                <div hidden={props.rdvState.company !== null}>
                    <CompanyLoading/>
                </div>
                <div hidden={props.rdvState.company === null}>
                    <MainSection/>
                </div>
                {/* <div hidden={showHeader == 0}>
                    <FooterSection/>
                </div> */}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppShell);
