import React from 'react';
import './OtherService.css'
import clockSvg from '../../assets/clock.svg'
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { GoCheck } from "react-icons/go";
import { Row, Col } from "react-bootstrap";
import { BsFillQuestionCircleFill } from "react-icons/bs"
import * as rdvActions from "../../actions";
import * as ckie from "../../cookieFunctions";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fromAppointmentToCurrentQueue: (a, o) => dispatch(rdvActions.fromAppointmentToCurrentQueue(a, o))
  }
}


const OtherService = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  let dispatch = useDispatch();
  const handleSelectOtherService = () => {
    dispatch({ type: 'SET_OPERATION', payload: "currentqueue" })
    dispatch({ type: 'SET_BRANCH', payload: props.rdvState.branch })
    // history.push('/' + sessionStorage.getItem('rdv_apikey') + "/service")
    //setCookie('currentQueueCookie', 'currentqueue', dtExpire, '/');
    // dispatch({ type: 'CURRENTQUEUE_OP' });
            
    //console.log('La valeur du cookie est : ', getCookie('currentQueueCookie'))
    let o;
    let a = props.rdvState.apiKey;
     let j = props.rdvState.operation;
    if (j === "appointment") {
      o = props.rdvState.branch.office;
    } else {
      o = props.rdvState.branch.id;
    }
    if (props.rdvState.operation === "appointment") {
      // sessionStorage.setItem("operationKey", props.rdvState.branch?.office)
      ckie.setSpecificCookie("operationKey", props.rdvState.branch?.office)
      props.fromAppointmentToCurrentQueue(a, o).then((response) => {
          // console.log(response);
        });

      }
     
    // window.location.href = '/' + props.rdvState.company?.apikey + '/' +  'currentqueue' + '/' + props.rdvState.branch?.office + '/' + localStorage.getItem('rdv_lang') + "?source=appointment"
    window.location.href = '/' + props.rdvState.company?.apikey + '/' +  'currentqueue' + '/' + props.rdvState.branch?.office + '/' + ckie.getSpecificCookie("rdv_lang") + "?source=appointment"
 
  }

  let otherService = t('other_reason') 
  let redirection_queue = t('directed_to_queue')


  var dtExpire = new Date();
  dtExpire.setTime(dtExpire.getTime() + 120 * 1000);

  function setCookie(cName, cValue, cExpire, cPath){
      document.cookie = cName + ' = ' + escape(cValue) + ' ' +
                ((cExpire == undefined) ? '' : ('; expires = ' + cExpire.toGMTString())) +
                ((cPath == undefined) ? '' : ('; path = ' + cPath));
    }
 
 
    function  getCookie(cName){
      if(document.cookie.length == 0)
        return null;
 
      var regSepCookie = new RegExp('(; )', 'g');
      var cookies = document.cookie.split(regSepCookie);
 
      for(var i = 0; i < cookies.length; i++){
        var regInfo = new RegExp('=', 'g');
        var infos = cookies[i].split(regInfo);
        if(infos[0] == cName){
          return unescape(infos[1]);
        }
      }
      return null;
    }



  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  let otherServiceNameNormalized = otherService[0].toUpperCase() + otherService.substr(1).toLowerCase()
  let otherServiceRedirection = redirection_queue[0].toUpperCase() + redirection_queue.substr(1).toLowerCase()

  return <div id={'OtherService'} onClick={handleSelectOtherService}>
    <div className="d-flex  mb-3 appointmentService"
          style={{border: `1px solid #CCCCCC`}}
    >
      <div className="d-flex">
      <BsFillQuestionCircleFill className={'my-auto'} style={{ width: `30px`, height: `30px`, marginLeft: `30px`   }} />
        </div>
      <div className="d-flex">
        <div className="d-flex flex-column my-auto">
          <div className="row"
            style={{letterSpacing: `-0.1px`, marginLeft: `10px`, font: `normal normal bold 16px/18px Helvetica Neue`, opacity: `1`, color: `#000000`}}>
            {otherServiceNameNormalized}</div>
          <div className="row"
            style={{marginLeft: `10px`, marginRight: `15px`, letterSpacing: `-0.1px`, font: `normal normal normal 14px/14px Helvetica Neue`, opacity: `1`, color: `#000000`}}>
            {otherServiceRedirection}</div>
          

        </div>
      </div>
      
    </div>
  </div>

};

export default connect(mapStateToProps, mapDispatchToProps)(OtherService);
