import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './FooterSection.css'
import logo from '../../assets/RIghtCom-logo.svg'
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

const mapStateToProps = (state) => {
    return {
      rdvState: state
    }
}

const FooterSection = (props) => {
    const {t} = useTranslation();
    let dark_footer = props.rdvState.company?.apikey === "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" || props.rdvState.company?.apikey === "amdGdnJsTnRUbVh5aERlWUFLWFFnQT09";
    return <footer className={props.rdvState.company?.color_scheme.isOrange === 1 || dark_footer ? 'nav-dark FooterSection d-flex align-items-center' : 'nav-dark FooterSection d-flex align-items-center'} id={'FooterSection'}>
        <Container>
            <Row>
                <Col className={'footerLeft'}>
                  <span className={'orange-body textTerms'}>{t('all_right')}{' '}
                      <a href={'https://right-com.com/gdpr'} rel={'noopener noreferrer'}
                         target={'_blank'}>{t('read_policy')}</a>{' '}|{' '}
                      <a href={'https://right-com.com/terms-of-service/'} rel={'noopener noreferrer'}
                         target={'_blank'}>{t('terms')}</a>
                  </span>
                </Col>
                <Col sm={"auto"} className={'d-flex align-items-center footerRight'}>
                    <span className={'orange-body textProduct'}>{t('product_of')}{' '}
                        <a href={'https://right-com.com'} rel={'noopener noreferrer'} target={'_blank'}
                           style={{marginLeft: 3}}><img src={logo}
                                                        alt={"RightCom"}/></a>
                    </span>
                    <span className={'orange-body textAbout'}>{t('learn_about')}
                        <a href={'https://right-com.com/product/appointment-scheduling-software/'}
                           rel={'noopener noreferrer'} target={'_blank'}>{' '}RightTime</a>
                    </span>
                </Col>
            </Row>
        </Container>
    </footer>;
};

export default connect(mapStateToProps)(FooterSection);
