import React from "react";
import "./AssistanceRedirection.css";
import { Row, Col } from "react-bootstrap";
import {RiInformationFill} from "react-icons/ri";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { GoCheck } from "react-icons/go";
import { BsFillQuestionCircleFill } from "react-icons/bs"
import * as rdvActions from "../../actions";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const AssistanceRedirection = (props) => {
  const { t } = useTranslation();
  const { apiKey } = useParams();

  

  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  

  return <div id={'OtherService'}>
    <div className="d-flex  mb-3 appointmentService"
          style={{border: `1px solid #CCCCCC`}}
    >
      <div className="d-flex">
      <RiInformationFill className={'my-auto'} style={{ width: `30px`, height: `30px`, marginLeft: `30px`   }} />
        </div>
      <div className="d-flex">
        <div className="d-flex flex-column my-auto">
          <div className="row"
            style={{letterSpacing: `-0.1px`, marginLeft: `10px`, font: `normal normal bold 16px/18px Helvetica Neue`, opacity: `1`, color: `#000000`}}>
            <a href="https://www.orange.ci/fr/assistance.html" className="redirectLink" target="_parent">
            {t("ask_info")}
            </a>
          </div>
          <div className="row"
            style={{marginLeft: `10px`, marginRight: `15px`, letterSpacing: `-0.1px`, font: `normal normal normal 14px/14px Helvetica Neue`, opacity: `1`, color: `#000000`}}>
            {/* {t("ask_info")} */}
            </div>
          

        </div>
      </div>
      
    </div>
  </div>
};

export default connect(mapStateToProps)(AssistanceRedirection);
