import React, { useEffect, useState } from 'react';
import { Col, Row, Modal, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import assistancePng from '../../assets/assistance.png';
import storesSvg from '../../assets/orangestores.svg';
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import moment from "moment";
import * as rdvActions from "../../actions";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'
import axios from "axios";
import constants from '../../constants';
import * as ckie from "../../cookieFunctions"
import "./CancelScreen.css"
// import ClockSvg from "../../assets/time.svg"

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getTicket: (e) => dispatch(rdvActions.getTicket(e)),
        getRdv: (e) => dispatch(rdvActions.getRdv(e)),
        getRDVInformation: (api_key, rdv_num) => dispatch(rdvActions.getRDVInformation(api_key, rdv_num)),
        cancelRDV: (api_key, rdv_num) => dispatch(rdvActions.cancelRDV(api_key, rdv_num))
    }
}

const CancelScreen = (props) => {

    const { t, i18n } = useTranslation();
    const { apiKey, rdvNum } = useParams();
    let history = useHistory();


    i18next.on('languageChanged', function (lng) {
        moment.locale(lng);
    });

    const [officeKey, setOfficeKey] = useState();
    let dispatch = useDispatch();
    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;

    useEffect(() => {
        setShow(true);
    }, [])
    useEffect(() => {
// console.log("The state ===> ", props.rdvState)
    }, [props.rdvState])

    const getRDV = (apiKey, rdvNum) => {
        let url = `https://booking.right-q.com/596045660/5/rt/getrdv/key/${apiKey}/rdvnum/${rdvNum}`;
        axios.get(url)
            .then(response => {
                //    console.log("response ===> ", response);
                //    console.log("office Key ===> ", response.data.data.office)
                    setOfficeKey(response.data.data.office)
                if (response.data.code !== 200) {
                    dispatch({ type: 'IS_LOADING', payload: true });
                    history.push("/noroute")
                }
                let appointmentDate = String(response.data.data.bookdate).split(" ");

                let visitor = {
                    phone: response.data.data.client_mobile,
                    fullname: response.data.data.client_fullname,
                    email: response.data.data.client_email,
                    language: response.data.data.language,
                };

                // localStorage.setItem("rdv_lang", response.data.data.language);
                ckie.setSpecificCookie("rdv_lang", response.data.data.language)

                i18n.changeLanguage(response.data.data.language)

                dispatch({ type: "SET_VISITOR", payload: visitor });
                dispatch({ type: 'SET_SLOT', payload: appointmentDate[1] })
                dispatch({ type: 'SET_DATE', payload: appointmentDate[0] })
                dispatch({ type: 'SET_SERVICE', payload: JSON.parse(response.data.data.t_service_name) })
                dispatch({ type: 'SET_BRANCH', payload: response.data.data.office_name })
                dispatch({ type: 'SET_OPERATION', payload: 'appointment' })
                dispatch({ type: 'CANCEL_RDV_STATUS', payload: response.data.data.status })
            })
            .catch(error => {
                //    console.log('error', error);               
            })
    }

    const getMenuItemLink = (menu_item) => {
        var redirect_url = constants["DEFAULT_ORANGE_URL"];
        if (Object.keys(constants["COMPANY_HEADER_URLS"]).indexOf(apiKey) !== -1) {
            redirect_url = constants["COMPANY_HEADER_URLS"][apiKey][menu_item];
        }
        return redirect_url;
    }
   
    const handleIcoClicked = () => {
        var redirect_url = constants["DEFAULT_ORANGE_URL"];
        if (Object.keys(constants["COMPANY_URLS"]).indexOf(apiKey) !== -1) {
            redirect_url = constants["COMPANY_URLS"][apiKey];
        }
        window.open(redirect_url, '_blank');
    }

    const handleYes_old = () => {
        dispatch({ type: 'SET_OPERATION', payload: null })
        dispatch({ type: 'SET_BRANCH', payload: null })
        dispatch({ type: 'SET_SERVICE', payload: null })
        dispatch({ type: 'SET_SLOT', payload: null })
        setShow(false)
        //window.location.href = '/' + props.rdvState.company?.apikey + '/' + props.rdvState.operation + '/' + props.rdvState.branch?.office + '/' + localStorage.getItem('rdv_lang')
    
        if (props.rdvState.source === 'appointment') {
            // window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + sessionStorage.getItem('operationKey')  + '/' + ckie.getSpecificCookie('rdv_lang')
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  "appointment" + '/' + ckie.getSpecificCookie('operationKey')  + '/' + ckie.getSpecificCookie('rdv_lang')
        } else {

            if(props.rdvState.operation === "currentqueue"){
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.id  + '/' + ckie.getSpecificCookie('rdv_lang')
      
          }else{
            window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + props.rdvState.branch?.office  + '/' + ckie.getSpecificCookie('rdv_lang')
          }
               

        }
        
    }

    const handleNo = () => {
        setShow(false)
        setDisplayFooterText(false)
    }

    const handleYes = () => {
        setShow(false)
        setDisplayFooterText(true)
        props.cancelRDV(apiKey, rdvNum).then((response) => {
                     getRDV(apiKey, rdvNum)
                 })
    }

    const handleNewAppointmentButtonClick = () => {
        //window.location.href = '/' + props.rdvState.company?.apikey + '/' +  props.rdvState.operation + '/' + officeKey  + '/' + ckie.getSpecificCookie('rdv_lang')
        window.location.href = getMenuItemLink("stores");
    }

    useEffect(() => {

        getRDV(apiKey, rdvNum);
    }, [])
    const [show, setShow] = useState(false);
    const [displayFooterText, setDisplayFooterText] = useState(false)



    const handleCancel = () => {

        setShow(true)
    }

const ConfirmationModal = (
     <Modal centered style={{ borderRadius: `0` }} show={show} onHide={handleNo}
            backdrop="static" keyboard={false} >
     <Modal.Header closeButton>
         <Modal.Title>{(props.rdvState.operation === 'currentqueue') ? (<div style={{ textSize: `24px`, opacity: `1` }}>{t('about_leave_queue')}</div>) : (
             <div style={{ font: `normal normal bold 24px/26px Helvetica Neue`, textSize: `24px`, opacity: `1` }}>{t('about_leave_appointment')}</div>)}</Modal.Title>
     </Modal.Header>
     <Modal.Body>{(props.rdvState.operation === 'currentqueue') ? (<div style={{ font: `normal normal bold 16px/19px Helvetica Neue` }}>{t('leave_queue_question')}</div>) : (<div style={{ font: `normal normal bold 16px/19px Helvetica Neue` }}>{t('cancel_appointment_question')}</div>)}
     </Modal.Body>
     <Modal.Footer className={'d-flex justify-content-start'}>
         <button style={{ background: `#FFFFFF`, border: `2px solid #000000`, opacity: `1`, textColor: `#000000`, width: `62px`, height: `40px` }} onClick={handleNo}>
             {t('no')}
         </button>
         <button style={{ border: `none`, background: `#FF7900`, textColor: `#000000`, opacity: `1`, width: `62px`, height: `40px` }} onClick={handleYes}>
             {t('yes')}
         </button>
     </Modal.Footer>
 </Modal>
)

    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px and max-width: 1023px)'
    })

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })
    return (<div>
{ConfirmationModal}

        <div style={{ background: `#000000`, height: isMobile ? `150px` : isTablet ? `250px` : `90px`, marginBottom: `50px` }}>

<Row className="d-flex flex-nowrap" style={{color: `white`, paddingLeft: isMobile? 20 : 150}}>
    <a href={getMenuItemLink("particulier")} className="menuLinkItem"><span className="MenuItemSpan">{t("menu_private")}</span></a>
    <a href={getMenuItemLink("professional")} className="menuLinkItem"><span className="MenuItemSpan">{t("menu_professionnal")}</span></a>
    <a href={getMenuItemLink("enterprise")} className="menuLinkItem"><span className="MenuItemSpan">{t("menu_entreprise")}</span></a>
    <a href={getMenuItemLink("stores")} className="menuLinkItem"><span className="MenuItemSpan"><img src={storesSvg} className="MenuItemSapnIcon"/>{t("menu_stores")}</span></a>
    <a href={getMenuItemLink("assistance")} className="menuLinkItem"><span className="MenuItemSpan"><img src={assistancePng} className="MenuItemSapnIcon"/>{t("menu_forum")}</span></a>
</Row>
            {isMobile ?
                <Row className="d-flex flex-nowrap">
                    <img onClick={handleIcoClicked} style={{cursor: `pointer`, marginRight: `40px`, marginTop: `15px`, marginBottom: `auto`, marginLeft: `20px`, height: `50px`, width: `50px` }} src="https://d14ivdfbam5frx.cloudfront.net/production-front-offices/orangesenegal/v1.4.0/assets/images/header/orange_logo_002.png" alt="logo orange" className="o-logo" />
                  <Row className="d-flex flex-nowrap" style={{overflow: `scroll`}}>  
                  <a href={getMenuItemLink("mobile")} className="menuLinkItem SubMenuItemSpan"><span className="SubMenuItemSpan">{t("submenu_mobile")}</span></a>
                  <a href={getMenuItemLink("internet")} className="menuLinkItem SubMenuItemSpan"><span className="SubMenuItemSpan">{t("submenu_internet")}</span></a>
                  <a href={getMenuItemLink("money")} className="menuLinkItem SubMenuItemSpan"><span className="SubMenuItemSpan">{t("submenu_o_money")}</span></a>
    <a href={getMenuItemLink("assistance")} className="menuLinkItem SubMenuItemSpan"><span className="SubMenuItemSpan">{t("submenu_assistance")}</span></a>
    </Row>
                </Row>
                :
                <Row className="d-flex flex-nowrap">
                    <img onClick={handleIcoClicked}
                     style={{cursor: `pointer`, marginTop: `15px`, marginRight: `40px`, marginBottom: `auto`, marginLeft: `150px`, height: `50px`, width: `50px` }} src="https://d14ivdfbam5frx.cloudfront.net/production-front-offices/orangesenegal/v1.4.0/assets/images/header/orange_logo_002.png" alt="logo orange" className="o-logo" />
                   <Row className="d-flex flex-nowrap" style={{overflow: `scroll`}}>
                        <a href={getMenuItemLink("mobile")} className="menuLinkItem SubMenuItemSpan">
                            <span className="SubMenuItemSpan">{t("submenu_mobile")}</span>
                        </a>
                        <a href={getMenuItemLink("internet")} className="menuLinkItem SubMenuItemSpan">
                            <span className="SubMenuItemSpan">{t("submenu_internet")}</span>
                        </a>
                        <a href={getMenuItemLink("money")} className="menuLinkItem SubMenuItemSpan">
                            <span className="SubMenuItemSpan">{t("submenu_o_money")}</span>
                        </a>
                        <a href={getMenuItemLink("assistance")} className="menuLinkItem SubMenuItemSpan">
                            <span className="Marg SubMenuItemSpan">{t("submenu_assistance")}</span>
                        </a>
                    </Row>
                </Row>
            }
        </div>

        
                { displayFooterText === true ?
            <div className="col-12">
                <h1 style={{ fontWeight: `bold`, marginLeft: `5px` }}>
                     {t("appointement_cancellation")}</h1>
                     </div>
                      : 
                     <div className="col-12"> <h1 style={{ fontWeight: `bold`, marginLeft: isMobile ? 5 : 60 }}>{t("appointement_details")}</h1>
            </div>
                }
           
        <hr className="col-sm-12 col-lg-12 col-md-12" style={{ marginBottom: `50px`, marginTop: `15px` }}></hr>

        <div className="Content">
            {displayFooterText === false ? <>
            
            <p className="ClientFullNameP">Hello, {props.rdvState.visitor?.fullname},</p>
            <p className="ClientFullNameP MarginSpace" >{t("appointement_details")}</p>
            
            </> : 
            <>
            <p className="ClientFullNameP">{t("cancelText1")}{props.rdvState.branch} {t("cancelText2")}</p>
            <p className="ClientFullNameP MarginSpace">{t("cancelText3")} {moment(props.rdvState.date, 'YYYY-MM-DD').locale(i18n.language).format("Do MMMM YYYY")}</p>
            </>}
            
            <div style={{height: isMobile ? 358 : 258}} className="DetailsContainer">
                <Row style={{marginBottom: `15px`}}>
                    <Col sm={5}>
                    <p className="LabelText">{t("reservation_code")} :</p>
                </Col>
                <Col sm={7}>
                <p className="ValueText">{rdvNum}</p>    
                </Col>
                </Row>
                
                <Row style={{marginBottom: `15px`}}>
                    <Col sm={5}>
                    <p className="LabelText">{t("service")} :</p>
                </Col>
                <Col sm={7}>
                <p className="ValueText">{props.rdvState.service ? props.rdvState.service[i18next.language] : props.rdvState.service?.name ? props.rdvState.service[i18next.language] : ''}</p>    
                </Col>
                </Row>
                
                <Row style={{marginBottom: `15px`}}>
                    <Col sm={5}>
                    <p className="LabelText">{t("date_time")} :</p>
                </Col>
                <Col sm={7}>
                <p className="ValueText">{props.rdvState.date ? moment(props.rdvState.date, 'YYYY-MM-DD').locale(i18n.language).format("dddd Do, MMMM YYYY") : moment().format("dddd Do, MMMM YYYY")} {" "} {t('at')} {" "} {props.rdvState.slot}</p>
                    </Col>
                    </Row>
                
                <Row style={{marginBottom: `15px`}}>
                    <Col sm={5}>
                    <p className="LabelText">{t("branch")} :</p>

                </Col>
                <Col sm={7}>
                    <p className="ValueText">{props.rdvState.branch}</p>

                </Col>
                </Row>
            </div>

{displayFooterText === true ? <>
                <Button className="NewAppointmentButton" onClick={handleNewAppointmentButtonClick}>
                    <span className="NewAppointmentButtonText">{t("new_appointment")}</span>
                </Button>

            <p className="ClientFullNameP">{t("thank_you")}</p>
            <p className="ClientFullNameP">Orange</p>
</> : null
}


                

        </div>





    </div>)
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelScreen);
