import React, {useEffect} from 'react';
import Select from "react-select";
import {connect, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import ArrowStepper from '../ArrowStepper/ArrowStepper';
import * as ckie from "../../cookieFunctions"

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const BranchSelector = (props) => {
  let dispatch = useDispatch();
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  //console.log(val_isOrange)
  const {t} = useTranslation();
  
  const colourStyles = {
    control: (styles, state) => ({
      ...styles, 
      backgroundColor: 'white', 
      height: val_isOrange === 1 ? 40 : 50, 
      border: val_isOrange === 1 ? '2px solid #CCC': null,
      boxShadow: state.isFocused ? props.rdvState.company?.color_scheme.primary_color ? props.rdvState.company?.color_scheme.primary_color : '#0089E1' : '', 
      borderRadius: val_isOrange === 1 ? 0 : 4, 
      borderColor: state.isFocused ? props.rdvState.company?.color_scheme.primary_color ? props.rdvState.company?.color_scheme.primary_color : '#0089E1' : '', 
      "&:hover": {borderColor: val_isOrange ? '#000': props.rdvState.company?.color_scheme.primary_color ? props.rdvState.company?.color_scheme.primary_color : '#0089E1'} }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? val_isOrange ? '#000': props.rdvState.company?.color_scheme.primary_color ? props.rdvState.company?.color_scheme.primary_color : '#0089E1' : '',
      color: state.isSelected || state.isFocused ? props.rdvState.company?.color_scheme.primary_color ? '#FFF' : null : '',
      fontFamily: val_isOrange === 1 ? 'helvetica-55-roman-1' : '',
      "&:hover": {color: val_isOrange === 1 ? '#FFF' : null}
    }),
  };
  /*
      ':active': {backgroundColor: val_isOrange === 1 ? '#000' : null, color: val_isOrange === 1 ? '#FFF' : null},
      */
  const handleChange = (v, a) => {
    if (props.fetchServices) {
      dispatch({type: 'IS_LOADING', payload: true})
      props.fetchServices(v)
      if (props.goBack) {
        props.goBack()
      }
    }
    // localStorage.setItem('rdv_prev_brach', JSON.stringify({o: props.rdvState.operation, b: v}))
    ckie.setSpecificCookie("rdv_prev_brach", JSON.stringify({o: props.rdvState.operation, b: v}))
    dispatch({type: 'SET_BRANCH', payload: v})
  }
  const compare = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }


  /*isOCI*/
let isOCI = props.rdvState.company?.apikey == "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09";
/*end isOCI*/

  useEffect(() => {
    let pb = JSON.parse(ckie.getSpecificCookie('rdv_prev_brach'))
    if (pb) {
      if ((props.rdvState.operation === pb.o) && !props.rdvState.branch) {
        if(!isOCI){
          dispatch({type: 'SET_BRANCH', payload: pb.b})
        }
      } else {
      }
    }

  },)

  
    




  return <div className={'BranchSelector'} id={'BranchSelector'}>
  <span hidden={isOCI}>
  <Select
      options={props.rdvState.branches.sort(compare)}

      components={val_isOrange === 1 ? {IndicatorSeparator: () => null}:''}
      getOptionLabel={o => o.name ? o.name : o.Name}
      getOptionValue={o => o}
      styles={colourStyles}
      clearable={true}
      placeholder={t('select_branch')}
      onChange={handleChange}
      value={props.rdvState.branch}
      noOptionsMessage={() => t('no_branch')}
      isDisabled={props.rdvState.branches.length === 0}
  />
  </span>
    <span hidden={!isOCI}>
    <Select required
        options={props.rdvState.branches.sort(compare)}

        components={val_isOrange === 1 ? {IndicatorSeparator: () => null}:''}
        getOptionLabel={o => o.name ? o.name : o.Name}
        getOptionValue={o => o}
        styles={colourStyles}
        clearable={true}
        placeholder={t('select_branch')}
        onChange={handleChange}
        noOptionsMessage={() => t('no_branch')}
        isDisabled={props.rdvState.branches.length === 0}
    />
    </span>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchSelector);
